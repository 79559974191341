import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";

const ImageSection = () => {

  const [groupedCarouselData, setGroupedCarouselData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const getOfferMasterData = useSelector(
    (state) => state.offerMasterReducer?.getofferMaster
  );



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(getOfferMasterData)) {
      const enabledData = getOfferMasterData.filter(
        (item) => item.enabled && item.placement === "Middle"
      );
      const sortedData = [...enabledData].sort(
        (a, b) => parseInt(a.displayOrder) - parseInt(b.displayOrder)
      );
      const groupedData = [];
      const length = sortedData.length;

      for (let i = 0; i < length; i++) {
        const currentItem = sortedData[i];
        const nextItem = sortedData[(i + 1) % length]; // Wrap around to the first item
        groupedData.push([currentItem, nextItem]);
      }
      setGroupedCarouselData(groupedData);
    }
  }, [getOfferMasterData]);

  return (
    <>
      {groupedCarouselData?.length > 0 && (
        <div className="mainDiv" id="imageSection">
          <Carousel interval={6000}>
            {groupedCarouselData.map((pair, index) => (
              <Carousel.Item key={index}>
                <section className="py-xl-4  py-4 mt-0 pt-0 earn">
                  <div className="container">
                    <div className="row m-xl-n3 m-md-n2 m-n1 mt-4">
                      {pair?.sort((a, b) => a.displayOrder - b.displayOrder)?.map((offerMasterData, imgIndex) =>
                        (isMobile && imgIndex === 0) || !isMobile ? (
                          <div className="col-lg-6 col-12" key={imgIndex}>
                            <div className="position-relative">
                              <Link to={offerMasterData?.link}>
                                <img
                                  src={`${process.env.REACT_APP_CLIENT_URL}${offerMasterData?.image}`}
                                  alt={
                                    offerMasterData?.alt ||
                                    `Image ${imgIndex + 1}`
                                  }
                                  className="d-block w-100 imgSec_H"
                                />
                              </Link>
                              {/* Optional: Add text overlay */}
                              {/* {offerMasterData.title && ( */}
                              <div className="position-absolute top-50 start-50 translate-middle text-white">
                                {/* {offerMasterData.title} */}
                              </div>
                              {/* )} */}
                            </div>
                          </div>
                        ) : null
                      )}
                    </div>
                  </div>
                </section>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default ImageSection;
