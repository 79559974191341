import { createSlice } from "@reduxjs/toolkit";

export const offerMaster = createSlice({
  name: "offerMaster",
  initialState: {
    getofferMaster: {},
  },
  reducers: {
    onGetofferMaster: (state) => {
      return {
        getofferMaster: {},
      };
    },
    onGetofferMasterSuccess: (state, { payload }) => {
      const { data = {}, message = "", status_code = 200 } = payload;
      return {
        ...state,
        getofferMaster: data,
        message,
        status_code,
      };
    },
    onGetofferMasterError: (state, { payload }) => {
      const { data = {}, message = "", status_code = 400 } = payload;
      return {
        ...state,
        getofferMaster: data,
        message,
        status_code,
      };
    },
    OnGetofferMasterReset: (state) => {
      return {
        ...state,
        getofferMaster: {},
        message: "",
        status_code: null,
      };
    },
  },
});

export const {
  onGetofferMaster,
  onGetofferMasterSuccess,
  onGetofferMasterError,
  OnGetofferMasterReset,
} = offerMaster.actions;

export default offerMaster.reducer;
