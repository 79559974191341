import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import defaultImage from "../../Assets/image 880.png";
import { useNavigate } from "react-router-dom";

const ShopByCategory = () => {
  const navigate = useNavigate();
  const [finalData, setFinalData] = useState([]);
  const categoryAllData = useSelector(
    (state) => state.userRoleSliceReducer.categoryData
  );

  useEffect(() => {
    if (categoryAllData) {
      const displayData = categoryAllData.filter(
        (item) => item?.displayHeader === false
      );

      const filterDisplayData = new Set();
      const uniqueDisplayData = displayData.filter((item) => {
        if (filterDisplayData.has(item.name)) {
          return false;
        } else {
          filterDisplayData.add(item.name);
          return true;
        }
      });

      // Sort the data based on displayOrder before setting it
      uniqueDisplayData.sort((a, b) => a.displayOrder - b.displayOrder);

      setFinalData(uniqueDisplayData);
    }
  }, [categoryAllData]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          fontSize: "16px",
          fontWeight: "bold",
        }}
        onClick={onClick}
      >
        {/* <span className="style_shop_cat_next_btn">  */}
        <i class="fa-solid fa-chevron-right fa-chevron-right-color style_next_btn"></i>
        {/* </span> */}
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none" }}
        onClick={onClick}
      />
    );
  }

  const handleImageClick = (item) => {
    navigate(`/productlist?categoryId=${item?.name}`, { state: { item } });
  };

  return (
    <>
      {finalData.length ? (
        <section className="py-xl-2 earn bg">
          <div className="container">
            <div className="">
              <h5 className="sec-below-head shop-head">Shop by category</h5>
            </div>
            <div className="row m-xl-n3 m-md-n2 m-n1 mt-4 spcate">
              <Slider className="owl-theme" {...settings}>
                {finalData
                  .sort((a, b) => a.displayOrder - b.displayOrder)
                  ?.map((item, index) => (
                    <div className="item text-center" key={index}>
                      <img
                        className="cat-img"
                        src={
                          item.image === "false"
                            ? defaultImage
                            : `${process.env.REACT_APP_CLIENT_URL}${item?.image}`
                        }
                        alt={item.name}
                        onClick={() => handleImageClick(item)}
                        style={{ cursor: "pointer" }}
                      />
                      <h5
                        className="cat-name"
                        onClick={() => handleImageClick(item)}
                      >
                        {item?.name}
                      </h5>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default ShopByCategory;
