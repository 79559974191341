import axiosIntegration from "../Common/Axios/axiosIntegration";
import API from "../Common/Endpoint/serviceConstrants";

export const callSwitchAccountPostApi = async (payload) => {
  const { data = [] } = await axiosIntegration.post(API.switchAccountApi, payload, {
    headers: {
      clientCode: process.env.REACT_APP_CLIENT_CODE,
      partnerCode: process.env.REACT_APP_PARTNER_CODE,
    },
  });
  return data;
};
