import { createSlice } from "@reduxjs/toolkit";

export const staticPointsSlice = createSlice({
  name: "static",
  initialState: {
    isLoading: false,
    isError: false,
    data: [],
    error: {},
    message: "",
  },
  reducers: {
    onStaticPointsSubmit: (state) => { 
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: [],
        message: "",
      };
      
    },

    onStaticPointsSuccess: (state, { payload }) => { 
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        data,
        message,
        status_code
      };
    },

    onStaticPointsError: (state, { payload }) => { 
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        data,
        message,
        status_code,
        isLoading: false,
        isError: true,
      };
    },
  
  },
});
export const { onStaticPointsSubmit, onStaticPointsSuccess, onStaticPointsError } =
staticPointsSlice.actions;

export default staticPointsSlice.reducer;
