import { call, put, takeLatest } from "redux-saga/effects";

import { promotionalApi } from "../Context/promotionalApi";
import {onGetPromotional,onGetPromotionalSuccess,onGetPromotionalError } from "../Store/Slices/promotionalSlice";
function* getPromotional() {
  try {
    const getPromotionalApi = yield call(promotionalApi);

    if (getPromotionalApi.httpStatusCode === "200") {
      yield put(
        onGetPromotionalSuccess({
          data: getPromotionalApi.response,
          message: getPromotionalApi.errorMessage,
          status_code: getPromotionalApi.httpStatusCode,
        })
      );
    } else {
      yield put(
        onGetPromotionalError({
          data: getPromotionalApi.response,
          message: getPromotionalApi.errorMessage,
          status_code: getPromotionalApi.httpStatusCode,
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetPromotionalError({ data: {}, message, status_code: 400 }));
  }
}
export default function* promotionalSaga() {
  yield takeLatest(onGetPromotional.type, getPromotional);
}
