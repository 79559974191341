import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import bgImage from "../../../src/Assets/Mask group.png";
import innerImg from "../../../src/Assets/image2giftcard.png";
import boxLine from "../../Assets/NewList/box-line.png";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

const EarnPointsModal = ({ show, handleClose, modalDataValue }) => {
  const navigate = useNavigate();
  const [moduleData, setModuleData] = useState([]);
  const [nav, setNav] = useState();
  const isLogin = sessionStorage.getItem("login");
  const pointData = useSelector((state) => state.pointsReducer?.data[0]);
  const V3Data = useSelector((state) => state.cardDataSliceReducer);
  const clientConfigurationData = useSelector(
    (state) => state.clientConfigurationReducer.clientConfigurationData
  );
  const client_Id = useSelector((state) => state.loginAuthReducer.data);
  const matchedClientData = clientConfigurationData.find((config) =>
    client_Id?.some((client) => client.clientId === config.clientId)
  );
  useEffect(() => {
    setNav(matchedClientData.enableQuickBy);
  }, [clientConfigurationData, client_Id]);

  const priceDenominations = modalDataValue?.priceDenominations
    ?.split(",")
    ?.map(Number);
  const [quantity, setQuantity] = useState(
    priceDenominations?.map((_, index) => (index === 0 ? 0 : 0))
  );
  const [finalAmount, setFinalAmount] = useState(0);
  const totalGiftcard = quantity?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const discount = modalDataValue?.customerDiscount;

  useEffect(() => {
    const newFinalAmount = priceDenominations?.reduce((acc, price, index) => {
      return acc + price * quantity[index];
    }, 0);
    setFinalAmount(newFinalAmount);
  }, [quantity, priceDenominations]);

  const handleIncrease = (index) => {
    setQuantity((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] += 1;
      return newQuantities;
    });
  };

  const handleDecrease = (index) => {
    setQuantity((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] = Math.max(newQuantities[index] - 1, 0);
      return newQuantities;
    });
  };

  useEffect(() => {
    const newArrayData = priceDenominations
      ?.map((item, index) => ({
        denomination: item,
        you_pay: item - (item * discount) / 100,
        saving: (item * discount) / 100,
        quantity: quantity[index],
      }))
      .filter((obj) => obj.quantity > 0);

    setModuleData(newArrayData);
  }, [quantity, discount]);

  const handleClick = () => {
    if (finalAmount > 0) {
      isLogin
        ? navigate(`/card?productId=${modalDataValue?.id}`, {
            state: { moduleData },
          })
        : (window.location.href =V3Data?.SignInUrl);
    } else {
      toast.error("Please add the product");
    }
  };
  const productDiscount = useSelector(
    (state) =>
      state?.ClientProductMappingbyIdReducer.data?.[0]?.clientProductMapping
  );
  const minEarnPoints = productDiscount?.find(
    (entry) => entry.productId === modalDataValue?.id
  );
  const minEarn = minEarnPoints?.minEarnPoints;
  const getMinPrice = (priceDenominations) => {
    // Ensure priceDenominations is a string
    const pricesArray = String(priceDenominations).split(",");

    // Convert the array of strings to an array of numbers
    const pricesNumbers = pricesArray.map(Number);

    // Find and return the minimum price
    return Math.min(...pricesNumbers);
  };

  return (
    <div className="modal_Quick">
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="exampleModalLabel"
        centered
      >
        <ToastContainer />
        <Modal.Body>
          <div
            id="amazon_gift_card"
            className="body position-relative-QuickModal"
          >
            {/* Fixed Header */}
            <div className="d-flex justify-content-between align-items-center fixed-top bg-white card_h_padding">
              <div>
                <h5 className="heading mb-0">{modalDataValue.name}</h5>
                <span className="Redeemable_online Redeemable_online_modal">
                  <span>
                    <i className="fa-solid fa-check check_icon"></i>
                  </span>
                  Redeemable Online
                </span>
                <span className="pro-discount pro-discount-modal">
                  {modalDataValue?.customerDiscount !== 0 &&
                    `Discount Flat ${modalDataValue?.customerDiscount}%`}
                </span>
              </div>

              <h5 className="mx-0 px-0" role="button" onClick={handleClose}>
                <i className="fa-solid fa-xmark xmark_FS"></i>
              </h5>
            </div>

            {/* Scrollable Content */}
            <div
              className="modal-content-scrollable  pb-3 pt-0"
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                scrollbarWidth: "none",
              }}
            >
              {priceDenominations?.map((item, index) => (
                <div
                  className="d-flex justify-content-between align-item-center mb-3 position_relatives"
                  key={index}
                >
                  <div className="d-flex modal_discount_price">
                    <div
                      className="background_image"
                      style={{ backgroundImage: `url(${bgImage})` }}
                    >
                      <span className="price_in_box">
                        <span>₹</span>
                        {(item - (item * discount) / 100) % 1 === 0
                          ? (item - (item * discount) / 100).toFixed(0)
                          : (item - (item * discount) / 100).toFixed(2)}
                      </span>
                      <img src={boxLine} />
                    </div>
                    <div className="font-blk mr-10 col-lg-3 col-3">
                      <p>
                        {modalDataValue?.customerDiscount !== 0 && (
                          <strike
                            className="pro-mrp"
                            style={{ textDecoration: "line-through" }}
                          >
                            ₹{item}
                          </strike>
                        )}
                        &nbsp;&nbsp;
                        <span className="actual-mrp">
                          ₹
                          {(item - (item * discount) / 100) % 1 === 0
                            ? Math.round(item - (item * discount) / 100)
                            : Math.round(item - (item * discount) / 100)}
                        </span>
                      </p>
                      {(minEarn / getMinPrice(priceDenominations)) *
                        (item - (item * discount) / 100) !==
                        0 && (
                        <div className="fa_star_border">
                          <span className="fa_star_box">
                            <i className="fa-solid fa-star fa-star_icon"></i>{" "}
                            {(
                              (minEarn / getMinPrice(priceDenominations)) *
                              (item - (item * discount) / 100)
                            ).toFixed(2)}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="number modal-inc_dec_btn d-flex align-item-center col-2">
                    {quantity[index] > 0 && (
                      <>
                        <button
                          className="minus"
                          onClick={() => handleDecrease(index)}
                        >
                          −
                        </button>
                        <input
                          className="item-qnty mod-qnty"
                          type="text"
                          value={quantity[index]}
                          readOnly
                        />
                      </>
                    )}
                    <button
                      className="plus"
                      onClick={() => handleIncrease(index)}
                    >
                      +
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {/* Fixed Footer */}
            <div className="fixed-bottom modal_bottom p-3 bg-white">
              <div className="d-flex justify-content-between">
                <span className="clr-blk lip">{totalGiftcard} Gift Card</span>
                <strong>
                  ₹
                  {(finalAmount - (finalAmount * discount) / 100) % 1 === 0
                    ? (finalAmount - (finalAmount * discount) / 100).toFixed(0)
                    : (finalAmount - (finalAmount * discount) / 100).toFixed(2)}
                </strong>
              </div>
              <Button
                className="col-12 buy-detail clr-white mt-3 btn-sz"
                onClick={handleClick}
              >
                Buy
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EarnPointsModal;
