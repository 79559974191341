import React, { useEffect, useState } from "react";
import './TNCPage.scss';
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const TNCPage = () => {
    const location = useLocation();
    const [data, setData] = useState();
    const productData = useSelector((state) => state.productReducer.productById?.[0]?.products);
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const productId = params.get("productId"); // Get productId from the query params

        if (productId) {
            const selectedProduct = productData.find(item => item.id === parseInt(productId)); // Find the product by ID
            setData(selectedProduct);
        }
    }, [location.search, productData]);

    return (
        <>
            <section className="tnc-widget tnc-bg-white">
                <div className="tnc-container">
                    <div className="tnc-justify-content-center tnc-img-alignment">
                        <img src={data?.small} id="ctl00_ContentPlaceHolder1_brandlogo" alt={data?.name} />
                    </div>
                    <div className="tnc-row">
                        <div className="tnc-col-sm-12">
                            <div className="tnc-product-details-head">
                                <div className="tnc-container">
                                    <div className="tnc-row tnc-align-items-center">
                                        <div className="tnc-col-sm-12 tnc-col-md-4 tnc-col-lg-3 tnc-text-center">
                                            {/* Empty section */}
                                        </div>
                                        <div className="tnc-col-sm-8 tnc-col-md-7 tnc-col-lg-6">
                                            <div className="tnc-row w-100 d-flex justify-content-center">
                                                <div className="tnc-col-sm-12 tnc-titleMob tnc-mt-2">
                                                    <h3></h3>
                                                </div>
                                                <div className="tnc-col-sm-12 col-md-6 hideOnMobile">
                                                    <div>
                                                        <p className="text-start tnc_para">
                                                            {data?.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tnc-row tnc-mt-4">
                        <div className="tnc-col-sm-12">
                            <h2 className="tnc-opiis">Important Instructions</h2>
                            <ul>
                                {data?.tncContent
                                    .replace(/<\/?[^>]+(>|$)/g, "") // Remove all HTML tags
                                    .split(/(?<=\w\.\s)/) // Split on period followed by a space
                                    .map((line, index) => (
                                        line.trim() && <li key={index}>{line.trim()}</li>
                                    ))}
                            </ul>

                        </div>
                    </div>

                    {/* <div className="tnc-row tnc-mt-4">
                        <div className="tnc-col-sm-12">
                            <h2 className="tnc-opiis">How to Redeem</h2>
                            <p>Walk into a Kalyan store</p>
                            <p>Select the applicable Kalyan product</p>
                            <p>At the billing counter, inform the cashier about the choice of making payment using Gift Cards.</p>
                            <p>The cashier selects Gift Card as the payment mode, submits card details in the system, and completes the billing process.</p>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    );
}

export default TNCPage;
