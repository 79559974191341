import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Slider = () => {
  const [groupedCarouselData, setGroupedCarouselData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const getCarouselData = useSelector(
    (state) => state.offerMasterReducer?.getofferMaster
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(getCarouselData)) {
      const enabledData = getCarouselData.filter(
        (item) => item.enabled && item?.placement === "Main"
      );
      const sortedData = [...enabledData].sort(
        (a, b) => parseInt(a.displayOrder) - parseInt(b.displayOrder)
      );

      // Group data for the carousel
      const groupedData = [];
      const length = sortedData.length;

      for (let i = 0; i < length; i++) {
        const currentItem = sortedData[i];
        const nextItem = sortedData[(i + 1) % length];
        groupedData.push([currentItem, nextItem]);
      }
      setGroupedCarouselData(groupedData);
    }
  }, [getCarouselData]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const autoSlideInterval = 6000;

  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === groupedCarouselData.length - 1 ? 0 : prevIndex + 1
        );
      }, autoSlideInterval);

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [isMobile, groupedCarouselData.length]);
  return (
    <>
      {groupedCarouselData.length ? (
      <>
      {isMobile ? (
        <div className="mobile-slider-wrapper">
          <section className="section-hero">
            <div className="container">
              <div
                className="hero justify-content-start top-slider"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  position: "relative",
                  width: "100%",
                }}
              >
                {groupedCarouselData.map((group, index) =>
                  group
                    ?.sort((a, b) => a.displayOrder - b.displayOrder)
                    ?.map((offerMasterData, idx) => (
                      <div
                        key={`${index}-${idx}`}
                        className="slide"
                        style={{
                          display: "inline-block",
                          width: "100%",
                          transform: `translateX(-${currentIndex * 100}%)`,
                          transition: "transform 0.5s ease-in-out",
                        }}
                      >
                        <Link to={offerMasterData?.link}>
                          <img
                            className="banner_image_width"
                            src={`${process.env.REACT_APP_CLIENT_URL}${offerMasterData?.mobileImage}`}
                            alt="Banner"
                            style={{ width: "100%" }}
                          />
                        </Link>
                      </div>
                    ))
                )}
              </div>
            </div>
          </section>
        </div>
      ) : (
        <Carousel interval={6000}>
          {groupedCarouselData.map((group, index) => (
            <Carousel.Item key={index}>
              <section className="section-hero pt-4">
                <div className="container">
                  <div className="row hero justify-content-center">
                    {group
                      ?.sort((a, b) => a.displayOrder - b.displayOrder)
                      ?.map((offerMasterData, idx) => (
                        <div className="col-lg-6" key={idx}>
                          <div className="side-ban-img">
                            <Link to={offerMasterData?.link}>
                              <img
                                className="banner_image_width"
                                src={`${process.env.REACT_APP_CLIENT_URL}${offerMasterData?.image}`}
                                alt="Banner"
                              />
                            </Link>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </section>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </>
      ) : (
        ""
      )}
    </>
  );
};

export default Slider;
