import React from 'react';
import LetShop3 from './card1';
import { useLocation } from 'react-router-dom';

const Card = () => {
    const location = useLocation();
    const { arrayData } = location.state || {};
    return (
        <div>
            <LetShop3 arrayData={arrayData} />
        </div>
    );
};

export default Card;