import API from "../Common/Endpoint/serviceConstrants";
import axiosIntegration from "../Common/Axios/axiosIntegration";
export const generateTokenApi = async (payload) => {
  const { data = {} } = await axiosIntegration.post(
    API.generate_token,
    {
      partnerCode: payload?.partnerCode,
      accessKey: payload?.accessKey,
      secrateKey: payload?.secrateKey,
    },
    {
      headers: {
        ClientCode: payload?.ClientCode,
        partnerCode: payload?.partnerCode,
      },
    }
  );
  return data;
};
