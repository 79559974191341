import { call, put, takeLatest } from "redux-saga/effects";
import {
    onGetofferMaster,
    onGetofferMasterSuccess,
    onGetofferMasterError,
} from "../../src/Store/Slices/offerMasterSlice";
import { offerMasterApi } from "../Context/offerMasterApi";


function* getofferMaster() {
  try {
    const getofferMasterResponse = yield call(offerMasterApi);

    if (getofferMasterResponse.httpStatusCode === "200") {
      yield put(
        onGetofferMasterSuccess({
          data: getofferMasterResponse.response,
          message: getofferMasterResponse.errorMessage,
          status_code: getofferMasterResponse.httpStatusCode,
        })
      );
    } else {
      yield put(
        onGetofferMasterError({
          data: getofferMasterResponse.response,
          message: getofferMasterResponse.errorMessage,
          status_code: getofferMasterResponse.httpStatusCode,
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetofferMasterError({ data: {}, message, status_code: 400 }));
  }
}
export default function* offerMasterSaga() {
  yield takeLatest(onGetofferMaster.type, getofferMaster);
}
