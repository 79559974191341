import React from "react";
import Slider from "../../Components/Slider/Slider";

const SectionHero = () => {
  return (
    <>
      <Slider className="slider" />
    </>
  );
};

export default SectionHero;