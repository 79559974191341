/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import bgImage from "../../../src/Assets/Mask group.png";
import innerImg from "../../../src/Assets/image2giftcard.png";
import infoIcon from "../../../src/Assets/info-circle1.png";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import boxLine from "../../Assets/NewList/box-line.png";
import { getMinPrice } from "../../Common/HOC/Hoc";

const RedemptionDetails = ({ productReducer }) => {
  const navigate = useNavigate();
  const [finalAmount, setFinalAmount] = useState(0);
  const [arrayData, setArrayData] = useState([]);
  const isLogin = sessionStorage.getItem("login");
  const location = useLocation();
  const { moduleData } = location.state || {};
  const productDiscount = useSelector(
    (state) =>
      state?.ClientProductMappingbyIdReducer.data?.[0]?.clientProductMapping
  );
  const minEarnPoints = productDiscount?.find(
    (entry) => entry.productId === productReducer?.productReducer?.id
  );
  const minEarn = minEarnPoints?.minEarnPoints;
  const priceDenominations =
    productReducer?.productReducer?.priceDenominations
      ?.split(",")
      .map(Number)
      .filter((num) => !isNaN(num)) || [];
  const discount =
    Number(productReducer?.productReducer?.customerDiscount) || 0;
  const pointData = useSelector((state) => state.pointsReducer?.data[0]);
  const V3Data = useSelector((state) => state.cardDataSliceReducer);

  // Initialize quantities to 1 for the first price denomination and 0 for others
  const initialQuantities = priceDenominations.map(
    (_, index) => index === 0 && 0
  );
  const [quantities, setQuantities] = useState(initialQuantities);
  useEffect(() => {
    if (moduleData) {
      const newQuantities = priceDenominations.map((price) => {
        const match = moduleData.find((item) => item.denomination === price);
        return match ? match.quantity : 0;
      });
      setQuantities(newQuantities);
    } else {
      setQuantities(initialQuantities);
    }
  }, [productReducer, moduleData]);

  const handleIncrease = (index) => {
    setQuantities((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] += 1;
      return newQuantities;
    });
  };

  const handleDecrease = (index) => {
    setQuantities((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      if (newQuantities[index] > 0) {
        newQuantities[index] -= 1;
      }
      return newQuantities;
    });
  };

  useEffect(() => {
    const newFinalAmount = priceDenominations.reduce((acc, price, index) => {
      return acc + price * quantities[index];
    }, 0);
    setFinalAmount(newFinalAmount);

    // Create the array of objects
    const newArrayData = priceDenominations
      .map((item, index) => ({
        denomination: item,
        you_pay: (item - (item * discount) / 100).toFixed(2),
        saving: ((item * discount) / 100).toFixed(2),
        quantity: quantities[index],
      }))
      .filter((obj) => obj.quantity > 0); // Filter out objects with quantity 0

    setArrayData(newArrayData);
  }, [quantities, discount]);
  const totalGiftcard = quantities.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const handleClick = () => {
    if (totalGiftcard > 10) {
      toast.error("Cannot add more than 10 products");
    } else if (totalGiftcard > 0) {
      if (!isLogin) {
        const pathName = window.location.pathname;
        const search = window.location.search;
        localStorage.setItem("ProductListPage", pathName + search);
        window.location.href =V3Data?.SignInUrl;
      } else {
        navigate(`/card?productId=${productReducer?.productReducer?.id}`, {
          state: { arrayData },
        });
      }
    } else {
      toast.error("Please add the product");
    }
  };
  

  return (
    <>
      <ToastContainer />
      <div key={productReducer?.productReducer?.productId} className="row">
        {priceDenominations.map((item, index) => (
          <div className="row p-2 align-card" key={index}>
            <div className="first-new col-lg-6 col-10">
              <div className="d-flex justify-content-start mb-3">
                <div>
                  <div
                    className="background_image"
                    style={{ backgroundImage: `url(${bgImage})` }}
                  >
                    <span className="price_in_box">
                      <span>₹</span>
                      {item}
                    </span>
                    <img src={boxLine} className="point-box-line" />
                  </div>
                </div>
                <div className="font-blk mr-10 col-lg-3 col-3">
                  <p>
                    {productReducer?.productReducer?.customerDiscount !== 0 && (
                      <strike
                        className="pro-mrp"
                        style={{ textDecoration: "line-through" }}
                      >
                        ₹{item}
                      </strike>
                    )}
                    &nbsp;&nbsp;
                    <span className="actual-mrp">
                      ₹
                      {(item - (item * discount) / 100) % 1 === 0
                        ? Math.round(item - (item * discount) / 100)?.toLocaleString()
                        : Math.round(item - (item * discount) / 100)?.toLocaleString()}
                    </span>
                  </p>
                  {(minEarn / getMinPrice(priceDenominations)) *
                    (item - (item * discount) / 100) !==
                    0 && (
                    <div className="fa_star_border">
                      <span className="fa_star_box">
                        <i className="fa-solid fa-star fa-star_icon buy"></i>{" "}
                        {Math.round(
                          (minEarn / getMinPrice(priceDenominations)) *
                          (item - (item * discount) / 100)
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="second-new col-lg-6 col-2 p-0 pl-2"
              style={{ marginRight: 0 }}
            >
              <div className="input forMob_rights">
                {quantities[index] > 0 && (
                  <button
                    className="minus"
                    aria-label="Decrease by one"
                    onClick={() => handleDecrease(index)}
                  >
                    <svg
                      width="16"
                      height="2"
                      viewBox="0 0 16 2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        y1="1"
                        x2="14"
                        y2="1"
                        stroke="#9B1E26"
                        strokeWidth="2"
                        className="icon"
                      ></line>
                    </svg>
                  </button>
                )}
                {quantities[index] > 0 && (
                  <div className="number dim mb-3 gift_qty_box">
                    {quantities[index]}
                  </div>
                )}
                <button
                  className="plus plus1"
                  aria-label="Increase by one"
                  onClick={() => handleIncrease(index)}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                  >
                    <line
                      x1="8"
                      y1="0"
                      x2="8"
                      y2="16"
                      stroke="#9B1E26"
                      strokeWidth="2"
                    ></line>
                    <line
                      y1="8"
                      x2="16"
                      y2="8"
                      stroke="#9B1E26"
                      strokeWidth="2"
                    ></line>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <>
        <div className="d-flex justify-content-between mt-4">
          <div className="belowvalue">
            <span className="lip">{totalGiftcard} Gift Card(s)</span>
            &nbsp;&nbsp;&nbsp;
            {(finalAmount - (finalAmount * discount) / 100) *
              (minEarn / getMinPrice(priceDenominations)) !==
              0 && (
              <div className="fa_star_border finalPoints">
                <span className="fa_star_box">
                  Earn <i className="fa-solid fa-star fa-star_icon buy"></i>{" "}
                  {Math.round(
                    (finalAmount - (finalAmount * discount) / 100) *
                    (minEarn / getMinPrice(priceDenominations))
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </div>
            )}
          </div>
          <div className="belowvalueright">
            <span className="prorightp">₹{Math.round(finalAmount).toLocaleString()}</span>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4">
          <div className="belowvalue">
            <span className="lip">Discount</span>
          </div>
          <div className="belowvalueright">
            <span className="prorightp">
              ₹
              {Math.round(finalAmount - (finalAmount - (finalAmount * discount) / 100)) %
                1 ===
              0
                ? Math.round(
                    finalAmount -
                    (finalAmount - (finalAmount * discount) / 100)
                  ).toLocaleString()
                : Math.round(
                    finalAmount -
                    (finalAmount - (finalAmount * discount) / 100)
                  ).toLocaleString()}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4">
          <div className="belowvalue">
            <span className="lip_totalAmount">Total Amount</span>
          </div>
          <div className="belowvalueright">
            <span className="prorightp">
              ₹
              {finalAmount - (finalAmount * discount) / 100
                ? Math.round(finalAmount - (finalAmount * discount) / 100)?.toLocaleString()
                : Math.round(finalAmount - (finalAmount * discount) / 100)?.toLocaleString()}
            </span>
          </div>
        </div>

        <div className="d-flex justify-content-between mt-4 p-2 point_cash_p_box">
          <p className="point_cash_p px-2">
            <span>
              <img className="infoImg mr-2" src={infoIcon} alt="info-icon" />
            </span>
            Pay with Points and Cash at checkout
          </p>
        </div>

        <div className="col-lg-12 col-12 mt-4 text-center mb-3 giftBuyBtn">
          <a
            className="clr-white buy-detailpro"
            onClick={handleClick}
            style={{ cursor: "pointer" }}
          >
            Buy Now
          </a>
        </div>
      </>
    </>
  );
};

export default RedemptionDetails;
