import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetTranslationData } from "../GetTranslationData/GetTranslationData ";
import { useLocation, useNavigate } from "react-router-dom";

const CategoryFilter = ({ categoryName, defaultData }) => {
  const shopByCategory = GetTranslationData("UIAdmin", "shopByCategory");
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [checkedCategoryData, setCheckedCategoryData] = useState([]);
  const categoryAllData = useSelector(
    (state) => state.userRoleSliceReducer.categoryData
  );
  const productByClientData = useSelector(
    (state) => state.productReducer.productById?.[0]?.products
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [category, setCategory] = useState({});

  useEffect(() => {
    if (categoryAllData && productByClientData) {
      const tempCategory = {};
      categoryAllData.forEach((categoryData) => {
        if (categoryData) {
          const data = productByClientData.find(
            (productData) => productData.id === categoryData.supplierBrandId
          );
          if (data) {
            if (!tempCategory[categoryData.name]) {
              tempCategory[categoryData.name] = [];
            }
            tempCategory[categoryData.name].push(data);
          }
        }
      });

      setCategory(tempCategory);

      // Check URL for categoryId and set state accordingly
      const params = new URLSearchParams(location.search);
      const cIds = params.get("categoryId");
      if (cIds) {
        const selectedCategories = cIds.split(",");
        let updatedCheckedCategoryData = [];
        selectedCategories.forEach((cId) => {
          const result = categoryAllData.find((record) => record.name === cId);
          if (result && tempCategory[result.name]) {
            updatedCheckedCategoryData = [
              ...updatedCheckedCategoryData,
              ...tempCategory[result.name],
            ];
          }
        });
        setCheckedCategories(selectedCategories);
        setCheckedCategoryData(updatedCheckedCategoryData);
        if (categoryName)
          categoryName(updatedCheckedCategoryData, selectedCategories);
      } else {
        // Use default data if no cIds in URL
        const defaultCategories = defaultData || [];
        setCheckedCategories(defaultCategories.map((d) => d.name));
        setCheckedCategoryData(
          defaultCategories.flatMap((d) => category[d.name] || [])
        );
        if (categoryName)
          categoryName(
            defaultCategories.flatMap((d) => category[d.name] || []),
            defaultCategories.map((d) => d.name)
          );
      }
    }
  }, [categoryAllData, productByClientData, location.search, defaultData]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const data = category[name];
    let updatedCheckedCategories = [...checkedCategories];
    let updatedCheckedCategoryData = [...checkedCategoryData];

    if (checked) {
      updatedCheckedCategories.push(name);
      updatedCheckedCategoryData = [
        ...updatedCheckedCategoryData,
        ...data.filter((item) => !checkedCategoryData.includes(item)),
      ];
    } else {
      updatedCheckedCategories = updatedCheckedCategories.filter(
        (categoryName) => categoryName !== name
      );
      updatedCheckedCategoryData = updatedCheckedCategoryData.filter((item) => {
        return Object.keys(category).some(
          (categoryName) =>
            updatedCheckedCategories.includes(categoryName) &&
            category[categoryName].includes(item)
        );
      });
    }

    setCheckedCategories(updatedCheckedCategories);
    setCheckedCategoryData(updatedCheckedCategoryData);
    if (categoryName)
      categoryName(updatedCheckedCategoryData, updatedCheckedCategories);

    const params = new URLSearchParams(location.search);
    params.set("categoryId", updatedCheckedCategories.join(","));
    navigate(`${location.pathname}?${params.toString()}`);
  };

  return (
    <div className="sidefilboxlist category-cont">
      <span>Category</span>

      <div
        id="categorufil2"
        className="filterinner collapse show"
        data-parent="#otherfilter"
      >
        <div className="checklistfilter">
          <div className="filktermaxht">
            {Object.keys(category).map((brand, index) => (
              <div key={index} className="brandsearch">
                <label className="checkbox-container">
                  <input
                  className="filter_category_checkbox"
                    id={`brand-${index}`}
                    type="checkbox"
                    name={brand}
                    checked={checkedCategories.includes(brand)}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor={`brand-${index}`}>{brand}</label>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryFilter;
