import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ExclamIcon from "../../Assets/ExclamIcon.png";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { Carousel } from "react-bootstrap";
import frame1 from "../../Assets/Frame 1707478818.png";
import frame2 from "../../Assets/Frame 1707478875.png";
import { useNavigate } from "react-router-dom";

const PaymentFailed = () => {
  const navigate = useNavigate();
  const images = [
    { src: frame1, alt: "Frame 1" },
    { src: frame2, alt: "Frame 2" },
  ];
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRetry = () => {
    navigate("/");
  };
  return (
    <>
      <ScrollToTop />
      <div className="bg-grey">
        <section
          className={`py-xl-3 pt-1 pb-2 mar-4 ${
            isMobile ? "mobile-view" : "desktop-hide"
          }`}
        >
          <div className="container-fluid txt-cnt">
            <div className="m-xl-n3 m-md-n2 m-n1">
              <div className="p-md-2 p-1 back">Payment Failed</div>
            </div>
          </div>
        </section>
        <section className="m-2 mt-4">
          <div className="container p-2 ">
            <div className="bg-wh">
              <div className="row">
                <div className="col-lg-12 col-12 mr-10">
                  <img src={ExclamIcon} alt="exclam" />
                  <div className="payment-message">
                    <h3>Payment Failed</h3>
                    <p className="font-p">
                      Your Payment for ‘Amazon Pay e-Gift Card’ could not be
                      processed
                    </p>
                  </div>
                </div>
                <span className="line-border"></span>
                <div className="payment-actions">
                  <button className="contact-support">Contact Support</button>
                  <button className="retry-payment" onClick={handleRetry}>
                    Retry Payment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {isMobile && (
          <section className="py-xl-4 py-4">
            <div className="container">
              <div className="row justify-content-center hero">
                <div className="col-lg-8">
                  {isMobile ? (
                    <Carousel>
                      {images.map((image, index) => (
                        <Carousel.Item key={index}>
                          <img
                            className="d-block w-100"
                            src={image.src}
                            alt={image.alt}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : (
                    <div className="d-flex justify-content-between">
                      {images.map((image, index) => (
                        <div key={index} className="col-6 p-1">
                          <img
                            className="d-block w-100"
                            src={image.src}
                            alt={image.alt}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default PaymentFailed;
