import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    isLoading: false,
    isError: false,
    orderData: {},
    error: {},
    message: "",
  },
  reducers: {
    onGetOrder: (state) => {
      return {
        ...state,
        isLoading: true,
        isError: false,
        orderData: {},
        error: {},
        message: "",
      };
    },

    onGetOrderSuccess: (state, { payload }) => {
      const { data = {}, message = "", status_code = 200 } = payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        orderData:data,
        message,
        status_code,
        error: {},
      };
    },

    onGetOrderError: (state, { payload }) => {
      const { data = {}, message = "", status_code = 400 } = payload;
      return {
        ...state,
        orderData:data,
        message,
        status_code,
        isLoading: false,
        isError: true,
        error: {},
      };
    },
    onGetOrderReset: (state) => {
      return {
        ...state,
        isLoading: false,
        orderData: {},
        message: "",
        error: {},
        status_code: null,
        isError: false,
      };
    },
      onPostOrder: (state) => {
        return {
          ...state,
          postLoading: true,
          isError: false,
          postOrderData: {},
          error: {},
          postMessage: "",
        };
      },
    onPostOrderSuccess: (state, { payload }) => {

      const { data = {}, message = "", status_code = 200 } = payload;
      return {
        ...state,
        postLoading: false,
        isError: false,
        postOrderData:data,
        postMessage:message,
        post_status_code:status_code,
        error: {},
      };
    },

    onPostOrderError: (state, {payload}) => {
      const { data = {}, message = "", status_code = 400 } = payload;
      return {
        ...state,
        postOrderData:data,
        postMessage:message,
        post_status_code:status_code,
        postLoading: false,
        isError: true,
        error: {}, 
      };
    },
    onPostOrderReset: (state) => {
      return {
        ...state,
        postLoading: false,
        postOrderData: null,
        postMessage: "",
        error: {},
        post_status_code: null,
        isError: false,
      };
    },

    onUpdateOrder: (state) => {
      return {
        ...state,
        isLoading: true,
        isError: false,
        updatedOrderData: {},
        error: {},
        updateMessage: "",
      };
    },

    onUpdateOrderSuccess: (state, { payload }) => {
      const { data = {}, message = "", status_code = 200 } = payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        updatedOrderData: data,  
        updateMessage:message,
        update_status_code:status_code,
        error: {},
      };
    },

    onUpdateOrderError: (state, { payload }) => {
      const { data = {}, message = "", status_code = 400 } = payload;
      return {
        ...state,
        updatedOrderData: data,
        updateMessage:message,
        update_status_code:status_code,
        isLoading: false,
        isError: true,
        error: {},
      };
    },
    onUpdateOrderReset: (state) => {
      return {
        ...state,
        isLoading: false,
        updatedOrderData: {},
        updateMessage: "",
        error: {},
        update_status_code: null,
        isError: false,
      };
    },
  },
});
export const { 
  onGetOrder, 
  onGetOrderSuccess, 
  onGetOrderError, 
  onGetOrderReset,
  onPostOrder, 
  onPostOrderSuccess, 
  onPostOrderError, 
  onPostOrderReset,
  onUpdateOrder,
  onUpdateOrderSuccess,
  onUpdateOrderError,
  onUpdateOrderReset
} = orderSlice.actions;

export default orderSlice.reducer;
