import { call, put, takeLatest } from "redux-saga/effects";
import { onGetAllocateBrand,onGetAllocateBrandError,onGetAllocateBrandSuccess,onAllocateBrandByIdSuccess,onAllocateBrandByIdError,onAllocateBrandById } from "../Store/Slices/allocateBrandSlice";
import { callAllocateBrandsGetApi,  callAllocateBrandsbyIdApi } from "../Context/allocateBrandApi";
function* getAddSpecial() {
    try {
      const allocateBrandsgetResponse = yield call(callAllocateBrandsGetApi);
  
      if (allocateBrandsgetResponse.httpStatusCode === "200") {
            yield put(
            onGetAllocateBrandSuccess({
            data: allocateBrandsgetResponse.response,
            message: allocateBrandsgetResponse.errorMessage,
            status_code: allocateBrandsgetResponse.httpStatusCode,
          })
        );
      } else {
        yield put(
            onGetAllocateBrandError({
            data: allocateBrandsgetResponse.response,
            message: allocateBrandsgetResponse.errorMessage,
            status_code: allocateBrandsgetResponse.httpStatusCode,
          })
        );
      }
    } catch (error) {
      const message = error.response || "Something went wrong";
      yield put(onGetAllocateBrandError({ data: [], message, status_code: 400 }));
    }
  }

function* getAllocateBrandsById({ payload }) {
    try {
        const AllocateBrandResponseById = yield call(callAllocateBrandsbyIdApi, payload);
        if (AllocateBrandResponseById.httpStatusCode === "200") {
                yield put(
                onAllocateBrandByIdSuccess({
                    data: AllocateBrandResponseById.response,
                    message: AllocateBrandResponseById.errorMessage,
                    status_code: AllocateBrandResponseById.httpStatusCode
                })
            );
        } else {
                yield put(
                onAllocateBrandByIdError({
                    data: AllocateBrandResponseById.response,
                    message: AllocateBrandResponseById.errorMessage,
                    status_code: AllocateBrandResponseById.httpStatusCode
                })
            );
        }
    } catch (error) {
        const message = error.response || "Something went wrong";
        yield put(
            onAllocateBrandByIdError({ data: {}, message, status_code: 400 })
        );
    }
}


export default function* allocateBrandSaga() {
    yield takeLatest(onGetAllocateBrand.type, getAddSpecial);
    yield takeLatest(onAllocateBrandById.type, getAllocateBrandsById);



}
