import { createSlice } from "@reduxjs/toolkit";

export const cardData = createSlice({
  name: "card_data",
  initialState: {
    getcardData: [],
  },
  reducers: {
    onSavePoints: (state, { payload }) => {
      return {
        ...state,
        points: Math.round(payload?.points),
        identifierType: payload?.identifierType,
        subIdentifier: payload?.subIdentifier,
      };
    },
    onSaveCreds: (state, { payload }) => {
      return {
        ...state,
        accessKey: payload?.accessKey,
        integrationSecretKey: payload?.integrationSecretKey,
        secrateKey: payload?.secrateKey,
        SignInUrl:payload?.SignInUrl,
        partnerCode:payload?.partnerCode,
        ClientCode:payload?.ClientCode,
        integrationId:payload?.integrationId
      };
    },
  },
});

export const { onSavePoints, onSaveCreds } = cardData.actions;

export default cardData.reducer;
