import { createSlice } from "@reduxjs/toolkit";

export const allocateBrandSlice = createSlice({
  name: "allocate-brand",
  initialState: {
    clientData: [],
    getAllocateBrandData:[]
  },
  reducers: {
    onGetAllocateBrand: (state) => {
      return {
        ...state,
        isLoading: false,
        isError: false,
        getAllocateBrandData: [],
        error: {},
        message: "",
      };
    },
    onGetAllocateBrandSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        getAllocateBrandData: data,
        message,
        status_code,
      };
    },
    onGetAllocateBrandError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getAllocateBrandData: data,
        message,
        status_code,
      };
    },
    onAllocateBrandById: (state) => {
      return {
        ...state,
        getAllocateBrandsById: [],
        getAllocateBrandsByIdMessage: "",
      };
    },
    onAllocateBrandByIdSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        getAllocateBrandsById: data,
        getAllocateBrandsByIdMessage: message,
        getAllocateBrandsById_status_code: status_code,
      };
    },
    onAllocateBrandByIdError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getAllocateBrandsById: data,
        getAllocateBrandsByIdMessage: message,
        getAllocateBrandsById_status_code: status_code,
      };
    },
  },
});

export const {
  onGetAllocateBrandError,
  onGetAllocateBrand,
  onGetAllocateBrandSuccess,
  onAllocateBrandById,
  onAllocateBrandByIdError,
  onAllocateBrandByIdSuccess,
} = allocateBrandSlice.actions;

export default allocateBrandSlice.reducer;
