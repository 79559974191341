import { call, put, takeLatest } from "redux-saga/effects";
import { onGetOrder, onGetOrderError, onGetOrderSuccess, onPostOrder, onPostOrderError, onPostOrderSuccess, onUpdateOrder, onUpdateOrderError, onUpdateOrderSuccess } from "../Store/Slices/orderSlice";
import { callOrderGetApi, callOrderPostApi, callOrderUpdateApi } from "../Context/orderApi";

function* GetOrder(payload) {
  try {
    const getOrderResponse = yield call(callOrderGetApi,payload);
    if (getOrderResponse.httpStatusCode === "200") {
      yield put(
        onGetOrderSuccess({
          data: getOrderResponse.response,
          message: getOrderResponse.response,
        })
      );
    } else {
      yield put(
        onGetOrderError({
          data: getOrderResponse.response,
          message: getOrderResponse.errorMessage,
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetOrderError({ data: {}, message, status_code: 400 }));
  }
}
function* PostOrder({ payload }) {
  try {
    const postOrderResponse = yield call(callOrderPostApi, payload);
    if (postOrderResponse.httpStatusCode === "201") {
      yield put(
        onPostOrderSuccess({
          postData: postOrderResponse.response,
          message: postOrderResponse.errorMessage,
          status_code: postOrderResponse.httpStatusCode,
        })
      );
    } else {
      yield put(
        onPostOrderError({
          data: postOrderResponse.response,
          message: postOrderResponse.errorMessage,
        })
      );
    }
  } catch (error) {
    // const message = error.response || "Something went wrong";
    yield put(onPostOrderError({ data: {}, message:error?.response?.data?.ErrorMessage, status_code: error?.response?.data?.HttpStatusCode }));
  }
}

function* UpdateOrder({ payload }) {
  try {
    const updateOrderResponse = yield call(
        callOrderUpdateApi,
      payload
    );
    if (updateOrderResponse.httpStatusCode === "201") {
      yield put(
        onUpdateOrderSuccess({
          data: updateOrderResponse.response,
          message: updateOrderResponse.errorMessage,
          status_code: updateOrderResponse.httpStatusCode,
        })
      );
    } else {
      yield put(
        onUpdateOrderError({
          data: updateOrderResponse.response,
          message: updateOrderResponse.errorMessage,
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onUpdateOrderError({ data: {}, message, status_code: 400 }));
  }
}

export default function* OrderSaga() {
  yield takeLatest(onGetOrder.type, GetOrder);
  yield takeLatest(onPostOrder.type, PostOrder);
  yield takeLatest(onUpdateOrder.type, UpdateOrder);
}
