import API from "../Common/Endpoint/serviceConstrants";
import axiosInstanceClient from "../Common/Axios/axiosInstanceClient";

export const callAllocateBrandsGetApi = async () => {
  const { data = []} = await axiosInstanceClient.get(API.allocate_Brand);
  return data;
};

export const callAllocateBrandsbyIdApi = async (payload) => {
  const { data = [] } = await axiosInstanceClient.get(
    `${API.allocate_Brand_Id}?AddSpecialId=${payload}`
  );
  return data;
};
