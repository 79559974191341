import axios from "axios";
const api = axios.create({
  baseURL: process.env.REACT_APP_INTEGRATION_URL_OTP,
  headers: {
    "Content-Type": "application/json",
    PartnerCode: process.env.REACT_APP_PARTNER_CODE_API,
    clientCode: process.env.REACT_APP_CLIENT_CODE,
    accept: "application/json",
  },
});

export default api;
