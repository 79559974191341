import API from "../Common/Endpoint/serviceConstrants";
import axiosInstance from '../Common/Axios/axiosInstance';

export const callProductByIdApi= async (payload) => { 
  const { data = [] } = await axiosInstance.get(
    `${API.product_By_Id}?clientId=${payload}`
  );
  return data;
};

export const callProductIdApi= async (payload) => { 
  const { data = [] } = await axiosInstance.get(
    `${API.product_By_Product_Id}?Id=${payload}`
  );
  return data;
};


