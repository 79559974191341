import { call, put, takeLatest } from "redux-saga/effects";
import { onGetSupplierBrandList, onGetSupplierBrandListError, onGetSupplierBrandListSuccess } from "../Store/Slices/supplierBrandListSlice";
import { callSupplierBrandListGetApi } from "../Context/supplierBrandListApi";

function* onGetSupplierList({payload}) {
  try {
    const supplierBrandListResponse = yield call(callSupplierBrandListGetApi,payload);
    if (supplierBrandListResponse.httpStatusCode==="200") {
      yield put(
        onGetSupplierBrandListSuccess({
          data: supplierBrandListResponse.response,
          message: supplierBrandListResponse.errorMessage,
        })
      );
    } else {
      yield put(
        onGetSupplierBrandListError({
          data: supplierBrandListResponse.result,
          message: supplierBrandListResponse.result.message,
        })
      );
    }
  } catch (error) {
      const message = error.response || "Something went wrong";
    yield put(onGetSupplierBrandListError({ data: [], message, status_code: 400 }));
  }
}

export default function* supplierBrandListSaga() {
  yield takeLatest(onGetSupplierBrandList.type, onGetSupplierList);
}