export const config = [
  {
    API_URL: "https://qa-cc.giftstacc.com",
    ACCESS_KEY: "3",
    SECRET_KEY: "3",
    PARTNER_KEY: "UIClient",
    SignInUrl: "https://qa-sso.ai-loyalty.com/Login/giftStacc12032023",
    accessKey: "kotak@#$gsapi@sapi",
    secrateKey: "KotakOug@EdeUy$##$",
    integrationSecretKey: "qaurq2EIgTy345GdtdRte-87657-ftDrt",
    partnerCode: "kotakgsapi",
    ClientCode: "qademo",
  },
  {
    API_URL: "https://bankofmaharashtra.giftstacc.com/",
    ACCESS_KEY: "3",
    SECRET_KEY: "3",
    PARTNER_KEY: "UIClient",
    SignInUrl: "https://qa-sso.ai-loyalty.com/Login/giftStacc12032023",
    accessKey: "kotak@#$gsapi@sapi",
    secrateKey: "KotakOug@EdeUy$##$",
    integrationSecretKey: "qaurq2EIgTy345GdtdRte-87657-ftDrt",
    partnerCode: "kotakgsapi",
    ClientCode: "qademo",
  },
  {
    API_URL: "https://qa-bom.giftstacc.com/",
    ACCESS_KEY: "3",
    SECRET_KEY: "3",
    PARTNER_KEY: "UIClient",
    SignInUrl: "https://qa-sso.ai-loyalty.com/Login/GIFTSTACCQA20240910U97DFGWBOM",
    accessKey: "bomss&$20T09QA#",
    secrateKey: "bomss@1@#!3#QA011",
    integrationSecretKey: "QABoM202410091444ccSc",
    partnerCode: "bomgsapi",
    ClientCode: "bom",
  },
  {
    API_URL: "https://uat-cc.giftstacc.com",
    ACCESS_KEY: "3",
    SECRET_KEY: "3",
    PARTNER_KEY: "UIClient",
    SignInUrl:
      "https://uat-sso.ai-loyalty.com/Login/GIFT-STACC-IDFC-UAT20241121",
    accessKey: "gs&$30T08#11",
    secrateKey: "gs@1@#!3#110",
    integrationSecretKey: "urq2EIsg5GDwstTOCFKu-Cv-cp901",
    partnerCode: "idfcgsapi",
    ClientCode: "qademo",
  },
  {
    API_URL: "https://uat-bom.giftstacc.com/",
    ACCESS_KEY: "3",
    SECRET_KEY: "3",
    PARTNER_KEY: "UIClient",
    SignInUrl: "https://uat-sso.ai-loyalty.com/Login/UATGSBOM20241121",
    accessKey: "Bom@#@@$#1220240906",
    secrateKey: "Bom@67t2024Idfc@#$0906",
    integrationSecretKey: "UATBoM202410091444ccSc",
    partnerCode: "bomgsapi",
    ClientCode: "bom",
  },
  {
    API_URL: "http://localhost:3000",
    ACCESS_KEY: "3",
    SECRET_KEY: "3",
    PARTNER_KEY: "UIClient",
    SignInUrl:"https://qa-sso.ai-loyalty.com/Login/GIFTSTACCQA20240910U97DFGWBOM",
    integrationId:"GIFTSTACCQA20240910U97DFGWBOM",
    accessKey: "bomss&$20T09QA#",
    secrateKey: "bomss@1@#!3#QA011",
    integrationSecretKey: "QABoM202410091444ccSc",
    partnerCode: "bomgsapi",
    ClientCode: "bom",
  },
];
