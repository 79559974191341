import { createSlice } from "@reduxjs/toolkit";

export const sessionLogoutSlice = createSlice({
  name: "sessionLogout",
  initialState: {
    isLoading: false,
    data: {},
    message: "",
  },
  reducers: {
    onSessionLogoutSubmit: (state) => {
      return {
        ...state,
        isLoading: true,
        data: {},
        message: "",
      };
    },

    onSessionLogoutSubmitSuccess: (state, { payload }) => {
      const { data = {}, message = "", status_code = 200 } = payload;
      return {
        ...state,
        isLoading: false,
        data,
        message,
        status_code
      };
    },

    onSessionLogoutSubmitError: (state, { payload }) => {
      const { data = {}, message = "", status_code = 400 } = payload;
      return {
        ...state,
        data,
        message,
        status_code,
        isLoading: false,
      };
    },
    onSessionLogoutReset: (state) => {
      return {
        ...state,
        message:"",
        status_code:null,
        isLoading: false,
      };
    },
  },
});
export const { onSessionLogoutSubmit, onSessionLogoutSubmitSuccess, onSessionLogoutSubmitError, onSessionLogoutReset } =
sessionLogoutSlice.actions;

export default sessionLogoutSlice.reducer;
