import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetTranslationData } from "../GetTranslationData/GetTranslationData ";

const DiscountFilter = ({ discount, product }) => {
  const discountHeading = GetTranslationData("UIAdmin", "discount");
  const [selectedDiscount, setSelectedDiscount] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const MIN = 0;
  const maxDiscount = product?.reduce((max, card) =>Math.max(max, card.customerDiscount), 0);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const discountValue = params.get("discount");
    if (discountValue) {
      const value = parseInt(discountValue, 10);
      setSelectedDiscount(value);
      if (typeof discount === "function") {
        discount(value);
      }
    }
     else {
      setSelectedDiscount(0);
      if (typeof discount === "function") {
        discount(null);
      }
    }
  }, [location.search, discount]);

  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedDiscount(value);
    // Update URL with selected discount value
    const params = new URLSearchParams(location.search);
    params.set("discount", value);
    navigate(`${location.pathname}?${params.toString()}`);
    // Call the discount function with new value if provided
    if (typeof discount === "function") {
      discount(value);
    }
  };

  return (
    <div className="sidefilboxlist">
      <div className="filterdrpbtn">
        <span>{discountHeading}</span>
      </div>
      <div
        id="disfilter"
        className="discount-slider collapse show"
        data-parent="#otherfilter"
      >
        <fieldset className="form-group1">
          <div className="rangeSlider d-flex filter-container">
            <input
              type="range"
              min={MIN}
              max={maxDiscount}
              value={selectedDiscount}
              className="rsBar1"
              onChange={(e)=>handleSliderChange(e)}
            />
          </div>
          <div className="box-minmax1">
            <span className="discount-filter">{selectedDiscount}%</span>
            <span className="discount-filter">{maxDiscount}%</span>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default DiscountFilter;
