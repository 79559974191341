import { call, put, takeLatest } from "redux-saga/effects";
import { onGetMember, onGetMemberError, onGetMemberSuccess } from "../Store/Slices/getMemberSlice";
import { callGetMemberApi } from "../Context/getMemberApi";
function* GetMember({ payload }) {
  try {
    
    const getMemberResponse = yield call(callGetMemberApi,payload);
    
    if (getMemberResponse.responseCode === "200") {
      yield put(
        onGetMemberSuccess({
          data: getMemberResponse,
          message: getMemberResponse.responseMessage,
          status_code:getMemberResponse.responseCode
        })
      );
    } else {
      yield put(
        onGetMemberError({
          data: getMemberResponse,
          message: getMemberResponse.response.message,
          status_code:getMemberResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response.data.responseMessage  || "Something went wrong";
    yield put(onGetMemberError({ data: [], message, status_code: 400 }));
  }
}
export default function* getMemberSaga() {
  yield takeLatest(onGetMember.type, GetMember);
}
