import { call, put, takeLatest } from "redux-saga/effects";
import { onClientConfigurationSubmit, onClientConfigurationSubmitError, onClientConfigurationSubmitSuccess } from "../Store/Slices/clientConfigurationSlice";
import { getClientConfigureApi } from "../Context/clientConfigurationApi";

function* clientConfiguration() {
  try {
    const clientConfiguration = yield call(getClientConfigureApi);
    if (clientConfiguration.httpStatusCode === "200") {
      yield put(
        onClientConfigurationSubmitSuccess({
          data: clientConfiguration.response,
          message: clientConfiguration.errorMessage,
        })
      );
    } else {
      yield put(
        onClientConfigurationSubmitError({
          data: clientConfiguration.response,
          message: clientConfiguration.errorMessage,
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(
      onClientConfigurationSubmitError({ data: [], message, status_code: 400 })
    );
  }
}


export default function* ClientConfigurationSaga() {
  yield takeLatest(onClientConfigurationSubmit.type, clientConfiguration);
}
