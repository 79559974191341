import { createSlice } from "@reduxjs/toolkit";

export const switchAccountSlice = createSlice({
  name: "switch-account",
  initialState: {
    isLoading: false,
    isError: false,
    switchAccountData: [],
    message: "",
  },
  reducers: {
    onPostSwitchAccount: (state) => {
      return {
        ...state,
        isPostLoading: true,
        switchAccountData: [],
      };
    },

    onPostSwitchAccountSuccess: (state, { payload }) => {
      const { postData = [], message = "", status_code="200" } = payload;
      return {
        ...state,
        isPostLoading: false,
        switchAccountData: postData,
        postMessage:message,
        post_status_code:status_code,
      };
    },

    onPostSwitchAccountError: (state, { payload }) => {
      const { postData = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        switchAccountData: postData,
        postMessage:message,
        post_status_code:status_code,
        isPostLoading: false,
      };
    },
    onPostSwitchAccountReset: (state) => {
      return {
        ...state,
        switchAccountData: [],
        postMessage:"",
        post_status_code:null
      };
    },
  }
});

export const {
  onPostSwitchAccount,
  onPostSwitchAccountSuccess,
  onPostSwitchAccountReset,
  onPostSwitchAccountError,
} = switchAccountSlice.actions;

export default switchAccountSlice.reducer;
