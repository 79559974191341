import { call, put, takeLatest } from "redux-saga/effects";
import { callSendMailPostApi } from "../Context/sendMailApi";
import { onPostSendMail, onPostSendMailError, onPostSendMailSuccess } from "../Store/Slices/sendMailSlice";

function* PostSendMail({ payload }) {
  try {
    const postSendMailResponse = yield call(callSendMailPostApi, payload);
    if (postSendMailResponse.responseCode === "200") {
      yield put(
        onPostSendMailSuccess({
          postData: postSendMailResponse.response,
          message: postSendMailResponse.responseMessage,
          status_code: postSendMailResponse.responseCode,
        })
      );
    } else {
      yield put(
        onPostSendMailError({
          data: postSendMailResponse.response,
          message: postSendMailResponse.responseMessage,
          status_code:postSendMailResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response.data.responseMessage  || "Something went wrong";
    yield put(onPostSendMailError({ data: [], message, status_code: 400 }));
  }
}
export default function* sendMailSaga() {
  yield takeLatest(onPostSendMail.type, PostSendMail);
}
