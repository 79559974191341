import CryptoJS from 'crypto-js';

// Key and IV (Initialization Vector)
const secretKey = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16 characters for AES-128
const iv = CryptoJS.enc.Utf8.parse('abcdefghijklmnop');         // 16 characters IV

// Function to encrypt
export const encryptAES = (data) => {
  let dataToEncrypt;

  // Determine if the data is an object/array or string
  if (typeof data === 'string') {
    dataToEncrypt = data; // Plain string
  } else if (typeof data === 'object') {
    dataToEncrypt = JSON.stringify(data); // Convert JSON/array to string
  } else {
    throw new Error("Unsupported data type for encryption"); // Validate unsupported types
  }

  const encrypted = CryptoJS.AES.encrypt(
    dataToEncrypt,
    secretKey,
    { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  );

  return encrypted.toString(); // Return Base64 string
};

// Function to decrypt
export const decryptAES = (cipherText) => {
  const decrypted = CryptoJS.AES.decrypt(
    cipherText,
    secretKey,
    { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  );

  const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

  // Try parsing as JSON; if it fails, return plain string
  try {
    return JSON.parse(decryptedText); // Convert to object/array if JSON
  } catch (error) {
    return decryptedText; // Return plain string if not JSON
  }
};