import React, { useEffect, useState } from "react";
import logo from "../../Assets/NewList/shop-logo.png";
import "./NewListingPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { onProductByIdSubmit } from "../../Store/Slices/productSlice";

const NewListingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const productData = useSelector(
    (state) => state.productReducer.productById?.[0]?.products
  );
  
  useEffect(() => {
    dispatch(onProductByIdSubmit(sessionStorage.getItem("clientCode")));
  }, []);

  const handleChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    setIsSearching(true);

    if (searchValue.trim() === "") {
      setFilteredResults([]);
      return;
    }

    const filteredData = productData?.filter((item) =>
      item?.name?.toLowerCase().includes(searchValue?.toLowerCase())
    );
    setFilteredResults(filteredData);
  };
  const handleClick = (item) => {
    navigate(`/tnc?productId=${item?.id}`);
    setSearchTerm("");
    setFilteredResults([]);
    setIsSearching(false);
  };
  return (
    <>
      <section  className="widget bg-white">
        <div className="container-fluid dam">
          <div className="row justify-content-center">
            <Link to="#" style={{width:"auto", margin:'10px'}}>
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="container">
            <div className="searchboxmaindiv search-def mb-3">
              <div id="#">
                <div className="searchbox big">
                  <input
                    type="text"
                    id="txtgiftsearch"
                    className="autosuggest"
                    placeholder="Search any Gift card"
                    value={searchTerm}
                      onChange={handleChange}
                  />
                  <span>
                    <i className="fa fa-search pt-2"></i>
                  </span>
                  <ul className="drop"></ul>
                </div>
                {searchTerm && (
                    <ul className="dropdown_Listing">
                      {filteredResults.length > 0
                        ? filteredResults.map((item) => (
                            <li
                              onClick={() => handleClick(item)}
                              key={item.id}
                              className="dropdown-item d-flex align-item-center"
                            >
                             <i className="las la-search mx-2" style={{ fontSize: '1.5em' }}></i> {item.name}
                            </li>
                          ))
                        : isSearching && (
                            <li className="dropdown-item">No records found</li>
                          )}
                    </ul>
                  )}
              </div>
            </div>
          </div>
          <div className="row">
            {productData?.map((item, index) => (
              <div className="col-lg-3 col-6 text-center">
                <div className="OfferProductBox">
                  <img className="w-50 my-4" src={item?.small} alt={item?.name} />
                  <figcaption>
                    <ul className="mb-4">
                      <span>
                        {/* <li>₹{item?.minPrice}</li> */}
                      </span>
                    </ul>
                    <div>
                      <Link to={`/tnc?productId=${item?.id}`}>Terms and Conditions</Link>
                    </div>
                  </figcaption>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default NewListingPage;
