import { createSlice } from "@reduxjs/toolkit";

export const categorySlice = createSlice({
  name: "create-category",
  initialState: {
    categoryData: [],
    error: {},
    message: "",
  },
  reducers: {
    onGetCategoryAll: (state) => {
      return {
        ...state,
        categoryData: [],
        error: {},
        message: "",
        isLoading:true
      };
    },

    onGetCategoryAllSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        categoryData:data,
        message,
        status_code,
        error: {},
        isLoading:false
      };
    },

    onGetCategoryAllError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        categoryData:[],
        message,
        status_code,
        error: {},
        isLoading:false
      };
    }
  },
});
export const { 
  onGetCategoryAll,
  onGetCategoryAllSuccess,
  onGetCategoryAllError
} = categorySlice.actions;

export default categorySlice.reducer;
