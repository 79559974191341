import { createSlice } from "@reduxjs/toolkit";

export const MemberSlice = createSlice({
  name: "get-member ",
  initialState: {
    isLoading: false,
    isError: false,
    MemberData: [],
    message: "",
    isgetLoading: false,
  },
  reducers: {
    onGetMember: (state) => {
      return {
        ...state,
        isgetLoading: true,
        MemberData: [],
        getmessage: "",
      };
    },

    onGetMemberSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isgetLoading: false,
        MemberData: data,
        getmessage:message,
        get_status_code:status_code,
      };
    },

    onGetMemberError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        MemberData: data,
        getmessage:message,
        get_status_code:status_code,
        isgetLoading: false,
      };
    },
    onGetMemberReset: (state) => {

      return {
        ...state,
        get_status_code:null,
        isgetLoading: false,
      };

    },
    onGetMemberLogout: (state) => {
      return {
        ...state,
        MemberData:null,
        get_status_code:null,
        isgetLoading: false,
      };

    },
  },
});

export const {
  onGetMember,
  onGetMemberSuccess,
  onGetMemberError,
  onGetMemberReset,onGetMemberLogout
} = MemberSlice.actions;

export default MemberSlice.reducer;
