import React from 'react'
import Card from '../../Assets/card.png'
import Upi from '../../Assets/upi.png'
import GPay from '../../Assets/gpay.png'
import PPay from '../../Assets/phnpay.png'
import { Link } from 'react-router-dom'
import Button from '../../Components/Button/Button'
import InputField from '../../Components/InputField/InputField'

const Checkout = () => {
    return (
        <>
            <section className="mt-100px mb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 pb20">
                            <div className="row justify-content-between">
                                <div className="order-tracking completed">
                                    <span className="is-complete"></span>
                                    <p>Add to Cart</p>
                                </div>
                                <div className="order-tracking completed">
                                    <span className="is-complete"></span>
                                    <p>Checkout</p>
                                </div>
                                <div className="order-tracking">
                                    <span className="is-complete"></span>
                                    <p>Order Placed</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="letshop3 pb-3">
                <div className="container mb-2 ">

                    <div className="row bnn pt-33 pb-4">
                        <div className="col-lg-6">
                            <h5 className="h-summ">Your order summary</h5>
                            <div className="container p-0">
                                <div className="row">
                                    <div className="col-lg-12 col-12 mt-4">
                                        <div className="pad3">
                                            <div className="d-flex justify-content-between mb-3">
                                                <h5 className="mb-1  fnt-bold"><span>₹ 12,000 &nbsp;</span>Amazon Gift Card</h5>
                                                <span className="de-counter">11.5% <i className="fi fi-ss-star"></i> Rewards Earned</span>
                                            </div>
                                            <div className="d-bb2 mr-2 mt-2">
                                                <div className="d-bb">
                                                    <p className="mbs">₹12,541/-</p>
                                                    <p><span className="offs">Discount Flat 5%</span></p>
                                                </div>
                                                <div className="">
                                                    <div className="d-flex justify-content-between text-center">
                                                        <div className="font-blk mr-10">
                                                            <p>You pay</p>
                                                        </div>
                                                        <div className="font-grn">
                                                            <p className="fnt-15">₹4,850</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="font-blk mr-10">
                                                            <p>Saving</p>
                                                        </div>
                                                        <div className="font-blk">
                                                            <p className="fnt-15">₹150</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-12 mt-4">
                                        <div className="pad3">
                                            <div className="d-flex justify-content-between mb-3">
                                                <h5 className="mb-1  fnt-bold"><span>₹ 12,000 &nbsp;</span>Amazon Gift Card </h5>
                                                <span className="de-counter">11.5% <i className="fi fi-ss-star"></i> Rewards Earned</span>
                                            </div>
                                            <div className="d-bb2 mr-2 mt-2">
                                                <div className="d-bb">
                                                    <p className="mbs">₹12,541/-</p>
                                                    <p><span className="offs">Discount Flat 5%</span></p>
                                                </div>
                                                <div className="">
                                                    <div className="d-flex justify-content-between text-center">
                                                        <div className="font-blk mr-10">
                                                            <p>You pay</p>
                                                        </div>
                                                        <div className="font-grn">
                                                            <p className="fnt-15">₹4,850</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="font-blk mr-10">
                                                            <p>Saving</p>
                                                        </div>
                                                        <div className="font-blk">
                                                            <p className="fnt-15">₹150</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ghh mt-3">
                                <div className="d-flex justify-content-between mb-2 ">
                                    <div className="gt">
                                        <span className="fnt-15">Sub Total</span>
                                    </div>
                                    <div className="font-grn">
                                        <span className="fnt-15">₹ 17000</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-2">
                                    <div className="gt">
                                        <span className="offs">Discount Flat 5%</span>
                                    </div>
                                    <div className="font-grn">
                                        <span className="fnt-15">₹ 850</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-2">
                                    <div className="gt">
                                        <span className="fnt-15">Points Used</span>
                                    </div>
                                    <div className="font-grn">
                                        <span className="fnt-15">₹ 450</span>
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between mb-2">
                                    <div className="gt">
                                        <span className="fnt-15">Total</span>
                                    </div>
                                    <div className="font-grn">
                                        <span className="fnt-15">₹ 15060</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-37px ">
                            <div className="thepoints mb-4">
                                <div className="d-flex justify-content-between the-bd">
                                    <h6 className="">Redeem Your </h6>
                                    <span className="thelcpts">480 Pts</span>
                                </div>
                                <div className="container">
                                    <form className="formWrap">
                                        <div className="d-flex d-flexCol">
                                            {/* Height Range slider Start */}
                                            <fieldset className="form-group">
                                                <div className="rangeSlider d-flex">
                                                    <div className="rsThumb" style={{ left: '25%' }}>
                                                        <div className="rsTooltip">50</div>
                                                    </div>
                                                    <InputField type="range" min="0" max="200" value="50" className="rsBar" />
                                                    <div className="rsProgress" style={{ left: '25%' }}></div>
                                                </div>
                                                <div className="box-minmax">
                                                    <span>0</span><span>200</span>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </form>
                                </div>
                                <div className="container userpagesbox d-none">
                                    <div className="row">
                                        <form className="formWrap">
                                            <div className="d-flex d-flexCol">
                                                <fieldset className="form-group">
                                                    <div className="title d-flex p-bottom-md t-upper">Height <span>Centimetre</span></div>
                                                    <div className="rangeSlider d-flex">
                                                        <InputField type="range" min="0" max="200" value="50" className="rsBar" />
                                                        <div className="rsThumb" style={{ left: '25%' }}>
                                                            <div className="rsTooltip">50</div>
                                                        </div>
                                                        <div className="rsProgress" style={{ left: '25%' }}></div>
                                                    </div>
                                                    <div className="rsMinMax d-flex">
                                                        <span>0</span><span>200</span>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </form>
                                        <div className="box-minmax">
                                            <span>0</span><span>150</span>
                                        </div>
                                        <span className="note-pts">Note: 1Pt - ₹ 1</span>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 thepoints">
                                <div className="">
                                    <div className="d-flex justify-content-between the-bd">
                                        <h6 className="">Amount Due </h6>
                                        <span className="mbs">₹12,541</span>
                                    </div>
                                    <div className="row p-4">
                                        <div className="col-lg-12">
                                            <p className="h-summ2">Payment Method</p>
                                            <div className="flfl">
                                                <img src={Card} alt="Card" />
                                                <img src={Upi} alt="UPI" />
                                                <img src={GPay} alt="GPay" />
                                                <img src={PPay} alt="PhonePay" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-md-2 d-grid mt-4">
                                        <Link to="/order-confirm" className="cnt-shop-blue">
                                            <Button
                                                className="btn py-3 py-md-2 btss2"
                                                text="Confirm &amp; Pay Now &nbsp;"
                                                icon="fa fa-arrow-right"
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-4 d-none">
                            <div className="last-sec">
                                <h6 style={{ fontSize: '12px', fontWeight: '600' }}>
                                    The funds from your purchase will be applied to an LC Reward Gift and will remain on that card until such time the Retailer Gift Card is Claimed by the Purchaser or Recipient.
                                </h6>
                                <span>
                                    Subject to LC Reward Gift Terms of Service, if after thirty-six (36) months of inactivity of an LC Reward Gift Choice Card, or if it has not been redeemed toward the purchase of eligible Retailer Gift Card during this period of time, then anytime thereafter, LC Reward Gift, may expire or apply dormancy fees to the LC Reward Gift Choice Card in accordance with applicable laws, or automatically redeem such LC Reward Gift Choice Card for a Retailer Gift Card selected by LC Reward Gift at LC Reward Gift's sole discretion, and send the LC Reward Gift selected Retailer Gift Card to the Purchaser or Recipient email address or SMS number on file.
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Checkout