import { createSlice } from "@reduxjs/toolkit";

export const ClientProductMappingbyIdSlice = createSlice({
  name: "ClientProductMappingbyId",
  initialState: {
    isLoading: false,
    isError: false,
    data: {},
    error: {},
    message: "",
  },
  reducers: {
    onGetClientProductMappingbyId: (state) => {
      return {
        ...state,
        isLoading: true,
        data: [],
        message: "",
        error: {},
        isError: false,
      };
    },
    onGetClientProductMappingbyIdSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200} = payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        data,
        error: {},
        message,
        status_code,
      };
    },
    onGetClientProductMappingbyIdError: (state, { payload }) => {
      const { data = {}, message = "", status_code } = payload;
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: data,
        message,
        status_code,
      };
    },
  },
});
export const {
  onGetClientProductMappingbyId,
  onGetClientProductMappingbyIdSuccess,
  onGetClientProductMappingbyIdError,
} = ClientProductMappingbyIdSlice.actions;

export default ClientProductMappingbyIdSlice.reducer;
