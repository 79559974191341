import { call, put, takeLatest } from "redux-saga/effects";
import { onStaticPointsSubmit, onStaticPointsError, onStaticPointsSuccess } from "../Store/Slices/staticPointSlice";
import { staticApi } from "../Context/staticPointApi";
function* StaticPoint({ payload }) {
  try {
    const postMemberResponse = yield call(staticApi, payload);
    if (postMemberResponse.httpStatusCode === "200") {
      yield put(
        onStaticPointsSuccess({
          data: postMemberResponse.response,
          message: postMemberResponse.response,
        })
      );
    } else {
      yield put(
        onStaticPointsError({
          data: postMemberResponse.response,
          message: postMemberResponse.errorMessage,
          status_code:postMemberResponse.httpStatusCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onStaticPointsError({ data: {}, message, status_code: error?.response?.data?.httpStatusCode }));
  }
}
export default function* staticPointSaga() {
  yield takeLatest(onStaticPointsSubmit.type, StaticPoint);
}
