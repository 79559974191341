import Auth from './Pages/Auth/Auth';
import { BrowserRouter } from 'react-router-dom'
import './scss/index.scss';

function App() {
  return (
    <BrowserRouter >
   <Auth/>
   </BrowserRouter>
  );
}

export default App;
