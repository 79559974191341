import API from "../Common/Endpoint/serviceConstrants";
import axiosIntegration from "../Common/Axios/axiosIntegration";
export const validateLoginTokenApi = async (payload) => {
  const { data = {} } = await axiosIntegration.post(
    API.validate_login_token,
    {
      loginSessionToken: payload?.loginSessionToken,
      integrationSecretKey: payload?.integrationSecretKey,
      temporaryToken: payload?.temporaryToken,
      urlReferrer: payload?.urlReferrer,
    }
  );
  return data;
};
