import { createSlice } from "@reduxjs/toolkit";

export const clientConfigurationSlice = createSlice({
  name: "clientConfiguration",
  initialState: {
    isLoading: false,
    isError: false,
    clientConfigurationData: [],
    error: [],
    message: "",
  },
  reducers: {
    onClientConfigurationSubmit: (state) => {
      return {
        ...state,
        isLoading: true,
        clientConfigurationData: [],
        message: "",
        error: [],
        isError: false,
      };
    },
    onClientConfigurationSubmitSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        clientConfigurationData: data,
        error: [],
        message,
        status_code,
      };
    },
    onClientConfigurationSubmitError: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isLoading: false,
        isError: true,
        clientConfigurationData: data,
        message,
        status_code,
      };
    },
    onClientConfigurationReset: (state) => {
      return {
        ...state,
        isLoading: false,
        clientConfigurationData: [],
        message: "",
        error: [],
        status_code: null,
        isError: false,
      };
    },
  },
});

export const {
  onClientConfigurationSubmit,
  onClientConfigurationSubmitError,
  onClientConfigurationSubmitSuccess,
  onClientConfigurationReset,
} = clientConfigurationSlice.actions;

export default clientConfigurationSlice.reducer;
