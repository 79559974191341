import React, {  useState } from "react";
import {  useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../Components/Button/Button";
import InputField from "../../Components/InputField/InputField";

const AddToCart = () => {
  const ProductData = useSelector(
    (state) => state?.supplierBrandListReducer?.data
  );

  const [value, setValue] = useState(1);
  return (
    <>
      <section className="mb-4 mt-100px">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 ">
              <div className="row justify-content-between">
                <div className="order-tracking completed">
                  <span className="is-complete"></span>
                  <p>Add to Cart</p>
                </div>
                <div className="order-tracking">
                  <span className="is-complete"></span>
                  <p>Checkout</p>
                </div>
                <div className="order-tracking">
                  <span className="is-complete"></span>
                  <p>Order Placed</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="letshop3 mt-2">
        <div className="container  mb-2">
          <div className="row">
            <div className="col-lg-8">
              {ProductData.filter(item=>item.enabled===true).map((item, index) => (
                <div
                  key={index}
                  className="row mx-0 border-bottom py-3 cartGrid bnn"
                >
                  <div className="col-lg-4 ">
                    <div className="d-flex w-100 h-100 justify-content-center">
                      <Link to="/myntra" className="">
                        <div className="w-100">
                          <img alt="Myntra" src={item?.small} />
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-8 col-12 mt-3">
                    <span className="de-counter">
                      11.5% <i className="fi fi-ss-star"></i>
                      Rewards Earned
                    </span>
                    <div className="row justify-content-between pb-2 mt-3">
                      <div className="col-12 col-md-auto lh-1 cart-f">
                        {item?.name}
                      </div>
                      <div className="d-flex justify-content-between mt-2 ">
                        <div className="n-text">
                        {item?.url}
                        </div>
                        <span className="offs">Discount Flat 5%</span>
                      </div>
                    </div>
                    <div className="row justify-content-between align-items-end">
                      <div className="col-6 col-md-auto lh-1 font-grnc2">
                        You Pay
                      </div>
                      <div className="col-6 col-md-auto lh-1 ps-0">
                        <div className="text-nowrap text-end">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="d-flex no-flex align-items-center">
                              <span className="font-grnc">
                                ₹85 <s className="gkg">₹100</s>{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-between mt-2">
                      <div className="col-6 col-md-auto lh-1 font-grnc2">
                        Saving
                      </div>
                      <div className="col-6 col-md-auto lh-1 ps-0">
                        <div className="text-nowrap text-end">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="d-flex no-flex align-items-center">
                              <span className="font-grnc">₹15</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-success pb-3 fs-14">5% OFF</div>
                    <div className="row justify-content-between align-items-end mt-2">
                      <div className="col-md-auto col-12">
                        <ul className="list-inline mt-2">
                          <div className="mass">
                            <div className="input-group">
                              <InputField
                                type="button"
                                value="-"
                                onClick={() => setValue(value - 1)}
                                className="button-minus"
                                data-field="quantity"
                              />
                              <InputField
                                type="number"
                                step="1"
                                max=""
                                value={value}
                                name="quantity"
                                className="quantity-field"
                              />
                              <InputField
                                type="button"
                                value="+"
                                onClick={() => setValue(value + 1)}
                                className="button-plus"
                                data-field="quantity"
                              />
                            </div>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {/* form start */}
            </div>
            <div className="col-lg-4">
              <div className="">
                <div className="bg-white rightCart bnb">
                  <div className="row m-0">
                    <div className="col-12 fs-18 py-4  fw-400 points-div">
                      <div className="summryHeader d-flex justify-content-between align-items-center p-3 pt-1 pb-1">
                        <div>
                          <i className="fa-solid fa-cart-shopping fa-2x"></i>{" "}
                          &nbsp; <span className="fnt-24px">3 Item(s)</span>
                        </div>
                        <div style={{ fontWeight: "700", fontSize: "24px" }}>
                          ₹ 548
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between jlkk p-4 pt-2 pb-2 mt-2 mb-2">
                    <div className="lsss">Edit User Details</div>
                    <div style={{ fontWeight: "700" }}>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </div>
                  </div>
                  <div
                    id="ctl00_ContentPlaceHolder1_acoupn"
                    className="p-4 pt-2 pb-2 mt-2 mb-2 d-flex justify-content-between"
                  >
                    <div className="voucher-new w-100">
                      <div className="input-box">
                        <InputField
                          name="ctl00$ContentPlaceHolder1$txtcouponcode"
                          type="text"
                          id="ctl00_ContentPlaceHolder1_txtcouponcode"
                          placeholder="Enter coupon/gift code"
                          style={{ borderRadius: "4px" }}
                        />
                      </div>
                    </div>
                    <Link to="#" className="applyvc">
                      <div
                        className="couponsubmit"
                        style={{ borderRadius: "4px" }}
                      >
                        <InputField
                          type="submit"
                          name="ctl00$ContentPlaceHolder1$btnaddcoupon"
                          value="Apply"
                          // onclick='javascript:WebForm_DoPostBackWithOptions(new WebForm_PostBackOptions("ctl00$ContentPlaceHolder1$btnaddcoupon", "", true, "", "", false, false))'
                          id="ctl00_ContentPlaceHolder1_btnaddcoupon"
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="info p-4 pt-2 pb-2">
                    <div className="d-flex justify-content-between jlkk ">
                      <div>Deliver to:</div>
                      <div style={{ fontWeight: "700" }}>Jaswant Rawat</div>
                    </div>
                    <div className="d-flex justify-content-between jlkk">
                      <div>Mobile No:</div>
                      <div style={{ fontWeight: "700" }}>9650531790</div>
                    </div>
                    <div className="d-flex justify-content-between jlkk">
                      <div>Email:</div>
                      <div style={{ fontWeight: "700" }}>
                        jaswant@way2webworld.com
                      </div>
                    </div>
                  </div>
                  <div className="p-4 pt-2 pb-2 d-grid ">
                    <Link to="/finalcart" className="cnt-shop-blue">
                      <Button 
                      className="btn py-3 py-md-2 btss2"
                        text="Proceed to Checkout &nbsp;"
                        icon="fa fa-arrow-right "
                      />
                    </Link>
                  </div>
                  <div className="p-4 pt-2 pb-2 d-grid ">
                    <Link to="productcart.html" className="cnt-shop">
                      <Button
                         className="btn py-3 py-md-2 btss "
                         text="Continue Shopping &nbsp;"
                         icon="fa fa-arrow-right"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mb-4 d-none">
              <div className="last-sec">
                <h6 style={{ fontSize: "12px", fontWeight: "600" }}>
                  Amazon.com Terms and Conditions
                </h6>
                <span>
                  (*)Amazon.com Gift Cards ("GCs") sold by LC Reward Gift.com,
                  an authorized and independent reseller of Amazon.com Gift
                  Cards. Except as required by law, GCs cannot be transferred
                  for value or redeemed for cash. GCs may be used only for
                  purchases of eligible goods at Amazon.com or certain of its
                  affiliated websites. For complete terms and conditions, see
                  www.amazon.com/gc-legal. GCs are issued by ACI Gift Cards,
                  Inc., a Washington corporation. All Amazon ®, ™&amp; © are IP
                  of Amazon.com, Inc. or its affiliates. No expiration date or
                  service fees.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddToCart;
