import { call, put, takeLatest } from "redux-saga/effects";
import { callSegmentApi } from "../Context/segmentApi";
import { onSegmentError, onSegmentSubmit, onSegmentSuccess } from "../Store/Slices/SegmentSlice";



function* Segment({ payload }) {
    try {
        const SegmentResponseData = yield call(callSegmentApi, payload);
        if (SegmentResponseData.responseCode === "200") { 
            yield put(
                onSegmentSuccess({
                    data: SegmentResponseData.response,
                    status_code:SegmentResponseData.responseCode,
                })
            );  
        } else {
            yield put(
                onSegmentError({
                    data: SegmentResponseData.response,
                })
            );
        }
    } catch (error) {
        // const message = error.message || "Something went wrong";
       // yield put(onSegmentError({ data: {}, message, }));
    }
}
export default function* SegmentSaga() {
    yield takeLatest(onSegmentSubmit.type, Segment);
}
