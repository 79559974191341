import { createSlice } from "@reduxjs/toolkit";

export const memberSlice = createSlice({
  name: "member",
  initialState: {
    data: {},
    error: {},
    message: "",
  },
  reducers: {
    onMemberPost: (state) => {
      return {
        ...state,
        data: {},
        message: "",
      };
    },

    onMemberPostSuccess: (state, { payload }) => {
      const { data = {}, message = "", status_code = 200 } = payload;
      return {
        ...state,
        data,
        message,
        status_code
      };
    },

    onMemberPostError: (state, { payload }) => {
      const { data = {}, message = "", status_code = 400 } = payload;
      return {
        ...state,
        data,
        message,
        status_code,
      };
    },
  },
});
export const { onMemberPost, onMemberPostSuccess, onMemberPostError } =
memberSlice.actions;

export default memberSlice.reducer;
