import { call, put, takeLatest } from "redux-saga/effects";
import { onGetClientProductMappingbyId, onGetClientProductMappingbyIdError, onGetClientProductMappingbyIdSuccess } from "../Store/Slices/clientProductMappingbyIdSlice";
import { callClientProductMappingbyIdGetApi } from "../Context/clientProductMappingbyIdApi";

function* onClientProductMappingbyId({payload}) {
  try {
    const ClientProductMappingbyIdResponse = yield call(callClientProductMappingbyIdGetApi,payload);
    if (ClientProductMappingbyIdResponse.httpStatusCode==="200") {
      yield put(
        onGetClientProductMappingbyIdSuccess({
          data: ClientProductMappingbyIdResponse.response,
          message: ClientProductMappingbyIdResponse.errorMessage,
        })
      );
    } else {
      yield put(
        onGetClientProductMappingbyIdError({
          data: ClientProductMappingbyIdResponse.result,
          message: ClientProductMappingbyIdResponse.result.message,
        })
      );
    }
  } catch (error) {
      const message = error.response || "Something went wrong";
    yield put(onGetClientProductMappingbyIdError({ data: [], message, status_code: 400 }));
  }
}

export default function* ClientProductMappingbyIdSaga() {
  yield takeLatest(onGetClientProductMappingbyId.type, onClientProductMappingbyId);
}