import axios from "axios";
import { decryptAES, encryptAES } from "./encrypt";

// Create Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_Gateway,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor for encryption
api.interceptors.request.use(
  (config) => {
    // Check if the Content-Type is multipart/form-data
    const isMultipart = config.headers['Content-Type'] && config.headers['Content-Type'].includes('multipart/form-data');
    
    if (!isMultipart && config.data) {
      config.data = encryptAES(config.data);  // Encrypt request body for non-multipart data
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for decryption
api.interceptors.response.use(
  (response) => {
    if (response.data) {
      response.data = decryptAES(response.data);  // Decrypt response body
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;