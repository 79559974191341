import { createSlice } from "@reduxjs/toolkit";

export const promotional = createSlice({
  name: "promotional",
  initialState: {
    GetPromotional: [],
  },
  reducers: {
    onGetPromotional: (state) => {
      return {
        GetPromotional: [],
      };
    },
    onGetPromotionalSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        GetPromotional: data,
        message,
        status_code,
      };
    },
    onGetPromotionalError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        GetPromotional: data,
        message,
        status_code,
      };
    },
    OnGetPromotionalReset: (state) => {
      return {
        ...state,
        GetPromotional: [],
        message: "",
        status_code: null,
      };
    },
  },
});

export const {
  onGetPromotional,
  onGetPromotionalSuccess,
  onGetPromotionalError,
  OnGetPromotionalReset,
} = promotional.actions;

export default promotional.reducer;
