import { createSlice } from "@reduxjs/toolkit";

export const addSpecialSlice = createSlice({
    name: "addSpecial",
    initialState: {
        data: [],
    },
    reducers: {

        onGetAddSpecial: (state) => {
            return {
                ...state,
                getData: [],
                getmessage: ''
            };
        },
        onGetAddSpecialSuccess: (state, { payload }) => {
            const { data = [], message = '', status_code } = payload;
            return {
                ...state,
                getData: data,
                getmessage: message,
                status_code
            };
        },
        onGetAddSpecialError: (state, { payload }) => {
            const { data = [], message = '', status_code } = payload;
            return {
                ...state,
                getData: data,
                getmessage: message,
                status_code
            };
        },
    },
});
export const { onGetAddSpecial, onGetAddSpecialSuccess, onGetAddSpecialError } =
    addSpecialSlice.actions;

export default addSpecialSlice.reducer;
