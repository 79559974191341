/* eslint-disable react-hooks/exhaustive-deps */

import SectionHero from "../../Components/SectionHero/SectionHero";
import UnlockPoints from "../../Components/UnlockPoints/UnlockPoints";
import ShopByCategory from "../../Components/shopByCategory/ShopByCategory";
import ImageSection from "../../Components/ImageSection/ImageSection";
import TrendingGiftCards from "../../Components/TrendingGiftCard/TrendingGiftCards";
import TopSellingBrands from "../../Components/TopSellingBrands/TopSellingBrands";
const HomePage = () => {
  return (
    <>
      <SectionHero />
      <UnlockPoints />
      <ShopByCategory />
      <ImageSection />
      <TrendingGiftCards />
      <TopSellingBrands />
    </>
  );
};

export default HomePage;
