import { createSlice } from "@reduxjs/toolkit";

export const supplierBrandListSlice = createSlice({
  name: "supplierBrandList",
  initialState: {
    isLoading: false,
    isError: false,
    data: {},
    error: {},
    message: "",
  },
  reducers: {
    onGetSupplierBrandList: (state) => {
      return {
        ...state,
        isLoading: true,
        data: [],
        message: "",
        error: {},
        isError: false,
      };
    },
    onGetSupplierBrandListSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code } = payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        data,
        error: {},
        message,
        status_code,
      };
    },
    onGetSupplierBrandListError: (state, { payload }) => {
      const { data = {}, message = "", status_code } = payload;
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: data,
        message,
        status_code,
      };
    },
  },
});
export const {
  onGetSupplierBrandList,
  onGetSupplierBrandListSuccess,
  onGetSupplierBrandListError,
} = supplierBrandListSlice.actions;

export default supplierBrandListSlice.reducer;
