import { call, put, takeLatest } from "redux-saga/effects";
import { onGetAddSpecial, onGetAddSpecialSuccess, onGetAddSpecialError } from "../Store/Slices/addSpecialListSlice";
import { callAddSpecialListGetApi } from "../Context/addSpecialListApi";

function* OnGetAddSpecial() {
  try {
    const addSpecialResponse = yield call(callAddSpecialListGetApi);
    if (addSpecialResponse.httpStatusCode === "200") {
      yield put(
        onGetAddSpecialSuccess({
          data: addSpecialResponse.response,
          message: addSpecialResponse.errorMessage,
          status_Code: addSpecialResponse.httpStatusCode
        })
      );
    } else {
      yield put(
        onGetAddSpecialError({
          data: addSpecialResponse.response,
          message: addSpecialResponse.errorMessage,
          status_Code: addSpecialResponse.httpStatusCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetAddSpecialError({ data: {}, message, status_code: 400 }));
  }
}
export default function* addSpecialListSaga() {
  yield takeLatest(onGetAddSpecial.type, OnGetAddSpecial);
}
