import { createSlice } from "@reduxjs/toolkit";

export const pointsSlice = createSlice({
  name: "points",
  initialState: {
    isLoading: false,
    isError: false,
    data: [],
    error: {},
    message: "",
  },
  reducers: {
    onPointsSubmit: (state) => { 
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: [],
        message: "",
      };
      
    },

    onPointsSuccess: (state, { payload }) => { 
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        data,
        message,
        status_code
      };
    },

    onPointsError: (state, { payload }) => { 
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        data,
        message,
        status_code,
        isLoading: false,
        isError: true,
      };
    },
  
  },
});
export const { onPointsSubmit, onPointsSuccess, onPointsError } =
pointsSlice.actions;

export default pointsSlice.reducer;
