import React, { useEffect, useState } from 'react'
import Footer from './Footer/Footer'
import Header from './Header/Header'
import { useDispatch, useSelector } from 'react-redux';
import { onClientConfigurationSubmit } from '../Store/Slices/clientConfigurationSlice';

const Layout = (props) => {
  const { Component } = props;
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false); 
  
  useEffect(()=>{
    dispatch(onClientConfigurationSubmit());
  },[])
 
  return (
    <>
      <Header  setModalOpen={setModalOpen} />
      <Component />
      <Footer />
    </>
  )
}

export default Layout