import { call, put, takeLatest } from "redux-saga/effects";
import {callCreateCategoryAllGetApi,} from "../Context/createcategoryApi";
import {
  onGetCategoryAll,
  onGetCategoryAllError,
  onGetCategoryAllSuccess
} from "../Store/Slices/createCategorySlice";

function* GetCategoryAll() {
  try {
    const getCategoryAllResponse = yield call(callCreateCategoryAllGetApi);
    if (getCategoryAllResponse.httpStatusCode === "200") {
      yield put(
        onGetCategoryAllSuccess({
          data: getCategoryAllResponse.response,
          message: getCategoryAllResponse.response,
        })
      );
    } else {
      yield put(
        onGetCategoryAllError({
          data: getCategoryAllResponse.response,
          message: getCategoryAllResponse.errorMessage,
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetCategoryAllError({ data: {}, message, status_code: 400 }));
  }
}

export default function* createCategorySaga() {
  yield takeLatest(onGetCategoryAll.type, GetCategoryAll);
}
