import { call, put, takeLatest } from "redux-saga/effects";
import { onSessionLogoutSubmit, onSessionLogoutSubmitError, onSessionLogoutSubmitSuccess } from "../Store/Slices/sessionLogoutSlice";
import { sessionLogoutApi } from "../Context/sessionLogoutApi";

function* SessionLogOut({ payload }) {
  try {
    const sessionLogoutResponse = yield call(sessionLogoutApi, payload);
    if (sessionLogoutResponse.httpStatusCode === "200") {
      yield put(
        onSessionLogoutSubmitSuccess({
          data: sessionLogoutResponse.response,
          message: sessionLogoutResponse.response,
        })
      );
    } else {
      yield put(
        onSessionLogoutSubmitError({
          data: sessionLogoutResponse.response,
          message: sessionLogoutResponse.errorMessage,
          status_code:sessionLogoutResponse.httpStatusCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onSessionLogoutSubmitError({ data: {}, message, status_code: error?.response?.data?.httpStatusCode }));
  }
}
export default function* sessionLogoutSaga() {
  yield takeLatest(onSessionLogoutSubmit.type, SessionLogOut);
}
