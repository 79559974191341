import { createSlice } from "@reduxjs/toolkit";

export const bannerMaster = createSlice({
  name: "bannerMaster",
  initialState: {
    getbannerMaster: [],
  },
  reducers: {
    onGetbannerMaster: (state) => {
      return {
        getbannerMaster: [],
        isLoading:true
      };
    },
    onGetbannerMasterSuccess: (state, { payload }) => {
      const { data = [], message = "", status_code = 200 } = payload;
      return {
        ...state,
        getbannerMaster: data,
        message,
        status_code,
        isLoading:false
      };
    },
    onGetbannerMasterError: (state, { payload }) => {
      const { data = [], message = "", status_code = 400 } = payload;
      return {
        ...state,
        getbannerMaster: data,
        message,
        status_code,
        isLoading:false
      };
    },
    OnGetbannerMasterReset: (state) => {
      return {
        ...state,
        getbannerMaster: [],
        message: "",
        status_code: null,
        isLoading:false
      };
    },
  },
});

export const {
  onGetbannerMaster,
  onGetbannerMasterSuccess,
  onGetbannerMasterError,
  OnGetbannerMasterReset,
} = bannerMaster.actions;

export default bannerMaster.reducer;
