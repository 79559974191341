import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../Pages/HomePage/HomePage";
import ProductCart from "../Pages/ProductCart/ProductCart";
import OrderConfirm from "../Pages/OrderConfirm/OrderConfirm";
import Layout from "../Layout/Layout";
import AddToCart from "../Pages/AddToCart/AddToCart";
import Checkout from "../Pages/Checkout/Checkout";
import cmsPage from "../Pages/cmsPage/cmsPage";
import TempPage from "../Pages/TempPage/TempPage";
import MyProfileContent from "../Components/MyProfileContent/MyProfileContent";
import ContactUs from "../Pages/ContactUs/ContactUs";
import Logout from "../Pages/Logout/Logout";
import ProductList from "../Components/ProductList/ProductList";
import GiftCard from "../Components/Giftcards/AmazonGiftCard";
import Card from "../Components/Card/Card";
import OrderConfirmation from "../Components/OrderConfirmation/orderConfirmation";
import OTPModal from "../Components/Otp/otpScreen";
import GiftcardSummary from "../Components/GiftcardSummary/giftCardSummary";
import NewListingPage from "../Components/NewListingPage/NewListingPage";
import TNCPage from "../Components/NewListingPage/TNCPage";
import PaymentFailed from "../Components/PaymentFailed/PaymentFailed";
import RetrieveCode from "../Components/RetrieveCode/RetrieveCode";
import PageError from "../Components/PageError/PageError";
function RouteConfiq() {
  return (
      <Routes>
        <Route path="/" element={<Layout Component={HomePage} />} />
        <Route path="/listing" element={<NewListingPage />} />
        <Route path="/tnc" element={<TNCPage />} />
        <Route path="/product-cart" element={<Layout Component={ProductCart} />} />
        <Route path="/cart" element={<Layout Component={AddToCart} />} />
        <Route path="/checkout" element={<Layout Component={Checkout} />} />
        <Route path="/order-confirm" elem ent={<Layout Component={OrderConfirm} />} />
        <Route path="/static/:pagetype" element={<Layout Component={cmsPage} />} />
        <Route path="/myprofile" element={<Layout Component={MyProfileContent} />} />
        <Route path="/contact-us" element={<Layout Component={ContactUs} />} />
        <Route path="/productlist" element={<Layout Component={ProductList} />} />
        <Route path="/giftcard" element={<Layout Component={GiftCard} />} />
        <Route path="/card" element={<Layout Component={Card} />} />
        <Route path="/order-Confirmation" element={<Layout Component={OrderConfirmation} />} />
        <Route path="/otp" element={<Layout Component={OTPModal} />} />
        <Route path="/order-detail" element={<Layout Component={GiftcardSummary} />} />
        <Route path="/login/:loginSessionToken/:temporaryToken" element={<TempPage />} />
        <Route path="/payment-failed" element={<Layout Component={PaymentFailed} />} />
        <Route path="/orders" element={<Layout Component={RetrieveCode} />} />
        <Route path="/logout" element={<Logout/>} />
        <Route
          path="*"
          element={
            <PageError
              pageError={{
                StatusCode: "404",
                ErrorName: "Route not found",
                ErrorDesription: "The page you were looking for is not found!",
                url: "/",
                buttonText: "Back to home",
              }}
            />
          }
        />
      </Routes>
   
  );
}
export default RouteConfiq;
