import React, { useEffect, useState } from "react";
import ThePoints from "./Thepoints";
import UserDetails from "./UserDetails";
import Voucher from "./Voucher";
import Points from "./Points";
import Refundable from "./Refundabable";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { onProductByProductIdSubmit } from "../../Store/Slices/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const LetShop3 = (arrayData) => {
  const location = useLocation();
  const modalData = location.state?.moduleData;
  const dispatch = useDispatch();
  const [enhancedProductReducer, setEnhancedProductReducer] = useState(null);
  const productReducer = useSelector((state) => state.productReducer?.productByProductId);
  const productDiscount = useSelector((state) => state?.ClientProductMappingbyIdReducer?.data?.[0]?.clientProductMapping);

  useEffect(() => {
    if (productReducer && productDiscount) {
      const discount = productDiscount.find((discountItem) => discountItem.productId === productReducer[0]?.id);
      const customerDiscount = discount?.customerDiscount;
      setEnhancedProductReducer({
        ...productReducer[0],
        customerDiscount,
      });
    }
  }, [productReducer, productDiscount]);

  const url = window.location.href.split("=")[1];

  useEffect(() => {
    dispatch(onProductByProductIdSubmit(url));
  }, [url]);

  return (
    <section className="letshop3 bg">
      <ScrollToTop />
      <div className="container mb-2">
        <div className="flex-container">
          <div className="voucher-section">
            <Voucher
              productReducer={productReducer}
              productReducerDisocount={enhancedProductReducer}
              arrayData={arrayData}
              modalProductData={modalData}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LetShop3;
