import React, { useState } from "react"
import RedemptionDetails from "./RedemptionDetails ";
const GiftCardRedemptionSection = (productReducer) => {
  return (
    <section className="letshop px-4">
      <div className="container p-0 big-rr mb-2 ">
        <div className="row justify-content-center p-2">
          <div className="col-lg-6 col-12 p-0">
            {/* <div className="d-flex justify-content-between deno-back">
              <div className="deno">
                <span className="deno-text"> Denomination</span>
              </div>
              <div className="deno">
                <span className="deno-text"> Quantity</span>
              </div>
            </div> */}
            <RedemptionDetails productReducer={productReducer} />
          </div>
        </div>
      </div>
    </section>
  )

}
export default GiftCardRedemptionSection;