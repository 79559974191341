import React from 'react';
import Button from '../Button/Button';
import myProfile from "../../Assets/myprofile.png"
import edit from "../../Assets/edit 1.png"
import { useSelector } from 'react-redux';

const Dashboard = ({ activeTab }) => {
  const validateLoginTokenData=useSelector(state=>state.validateLoginTokenSliceReducer.data)
  return (
    <div
      className={` fade shadow rounded myprofilecontent ${activeTab === 'dashboard' ? ' active' : ''}`}
      id="v-pills-home"
      role="tabpanel"
    >
      <section className="">
        <div className="the-behind-box">
          <div className="d-flex justify-content-between" style={{ borderBottom: '1px solid #F2F2F2' }}>
            <div className="d-flex justify-content-between align-items-center w-100 px-5 pt-3 pb-4 mediaProfile">
              <div className="d-flex justify-content-start align-items-center">
                <img className="myproaa" src={myProfile} alt="profile-icon" />
                <p className="gkis">My Profile</p>
              </div>
              {/* <span className="my-pro-edit-bt">
                <img src={edit} alt="edit-icon" />&nbsp;Edit
              </span> */}
            </div>
          </div>
          <div className="row px-3 py-3 mediaProfileInput">
            <div className="col-lg-5 col-12 p_input_border">
              <div className="edit-profile-info">
                <label className="edit-pro-label" htmlFor="fname">
                  First Name
                </label>
                <input type="text" id="fname" name="fname" placeholder="Hrushikesh" value={validateLoginTokenData.firstName} />
              </div>
            </div>
            <div className="col-lg-5 col-12 p_input_border">
              <div className="edit-profile-info">
                <label className="edit-pro-label" htmlFor="lname">
                  Last Name
                </label>
                <input type="text" id="lname" name="lname" placeholder="Paygavhan" value={validateLoginTokenData.lastName} />
              </div>
            </div>

            <div className="col-lg-5 col-12 p_input_border">
              <div className="edit-profile-info">
                <label className="edit-pro-label" htmlFor="mobile">
                  Mobile No.
                </label>
                <input type="text" id="mobile" name="mobile" placeholder="+91-7972131196" value={validateLoginTokenData.mobileNumber} />
              </div>
            </div>

            <div className="col-lg-5 col-12 p_input_border">
              <div className="edit-profile-info">
                <label className="edit-pro-label" htmlFor="email">
                  Email id
                </label>
                <input type="text" id="email" name="email" placeholder="hp.paygavhan@gmail.com" value={validateLoginTokenData.emailId} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
