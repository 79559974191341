import { call, put, takeLatest } from "redux-saga/effects";
import { onMemberPost, onMemberPostError, onMemberPostSuccess } from "../Store/Slices/memberSlice";
import { memberApi } from "../Context/memberApi";

function* MemberPost({ payload }) {
  try {
    const postMemberResponse = yield call(memberApi, payload);
    if (postMemberResponse.httpStatusCode === "200") {
      yield put(
        onMemberPostSuccess({
          data: postMemberResponse.response,
          message: postMemberResponse.response,
        })
      );
    } else {
      yield put(
        onMemberPostError({
          data: postMemberResponse.response,
          message: postMemberResponse.errorMessage,
          status_code:postMemberResponse.httpStatusCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onMemberPostError({ data: {}, message, status_code: error?.response?.data?.httpStatusCode }));
  }
}
export default function* memberSaga() {
  yield takeLatest(onMemberPost.type, MemberPost);
}
