import React from 'react'
import { Link } from 'react-router-dom'

const DominosCard = ({ products }) => {

    return (
        <>
        {Array.isArray(products) && products.map((prod, index)=>
            <div className="col-lg-3 col-6" key={index}>
                <div className="box-coupen3">
                    <div className="img-sec">
                        <div className="bagde-flag-wrap">
                            <Link to="#" className="bagde-flag"> 2.5% Off</Link>
                        </div>
                        <img className="w-144" src={prod?.small} alt='img'/>
                    </div>
                    <div className="coupendis">
                        <div className="fnt-12px mb-1">{prod?.name}</div>
                        <div className="price-rr mb-1">{prod?.currencySymbol}{prod?.minPrice} - {prod?.currencySymbol}{prod?.maxPrice}</div>
                        <center>
                            <div className="prodiscounternew">
                                <i className="fi fi-ss-star"></i>
                                11.5% Rewards Earned
                            </div>
                        </center>
                    </div>
                </div>
            </div>
            )}
        </>
    )
}

export default DominosCard