import React from "react";
import DominosCard from "../Cards/DominosCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const LetshopHandpick = () => {
  const addSpecialData = useSelector((state) => state.addSpecialReducer.getData);
  const allocateBrandData = useSelector((state) => state?.allocateBrandReducer?.getAllocateBrandData);
  const productByClientData = useSelector(state => state.productReducer.productById?.[0]?.products)

  const filteredAddSpecial = Array.isArray(addSpecialData) && addSpecialData
    .filter(data => data.enabled === true && data.isSpecial === false)
    .map(addSpecial => {
      const allocatedBrands = allocateBrandData.filter(brand => brand.addSpecialId === addSpecial.id);
      const products = allocatedBrands.map(brand => {
        const product = productByClientData?.find(product => product.id === brand.productId);
        return product;
      });

      return {
        sectionName: addSpecial.sectionName,
        sectionDesc: addSpecial.description,
        buttonText: addSpecial.buttonText,
        displayOrder: addSpecial.displayOrder,
        products: products
      };
    });
    Array.isArray(filteredAddSpecial)&&filteredAddSpecial?.sort((a, b)=> a.displayOrder-b.displayOrder);

    return (
      <>
      {Array.isArray(filteredAddSpecial) && filteredAddSpecial.map((item, index) => (
        <section className="letshop esec-mar" key={index}>
          <div className="container">
            <div className="row ">
              <div className="d-flex justify-content-between mb-4">
                <div className="first w-70 mb-2" >
                  <h5 className="clc2">{item?.sectionName}</h5>
                  <p className="the-para-magic23 mobile-hide">
                    {item?.sectionDesc}
                  </p>
                </div>
                <div className="second">
                  <Link className="avail23  mt-4" to="#">
                    {item?.buttonText} &nbsp;
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <DominosCard products={filteredAddSpecial[index].products} />
            </div>
          </div>
        </section>
      ))}
    </>
  );
};

export default LetshopHandpick;
