import React from 'react'
import { Link } from 'react-router-dom'

const AmazonGiftCard = () => {
    return (
        <>
            <div className="col-lg-6 col-12 mt-4">
                <div className="pad2">
                    <div className="d-flex justify-content-between mb-3">
                        <h5 className="mb-1 fnt-bold procardname"><span>₹ 12,000 &nbsp;</span>Amazon Gift Card </h5>
                        <span className="de-counter">11.5% <i className="fi fi-ss-star"></i>
                            Rewards Earned</span>
                    </div>
                    <div className="d-bb2 mr-2 mt-2">
                        <div className="d-bb">
                            <p className="mbs">₹12,541/-</p>
                            <p><strike className="gkg">₹16,999/-</strike>&nbsp; <span className="offs">26%off</span></p>
                        </div>
                        <div className="">
                            <div className="d-flex justify-content-between text-center">
                                <div className="font-blk mr-10">
                                    <p>You pay</p>
                                </div>
                                <div className="font-grn">
                                    <p className="fnt-15">₹4,850</p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="font-blk mr-10">
                                    <p>Saving</p>
                                </div>
                                <div className="font-blk">
                                    <p className="fnt-15">₹150</p>
                                </div>
                            </div>
                        </div>
                       <Link to="/cart" className="proaddtocartbtn"><i className="fa-solid fa-cart-plus"></i></Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AmazonGiftCard