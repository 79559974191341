/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import RightCart from "./RightCard";
import ThePoints from "./Thepoints";
import Refundable from "./Refundabable";
import UserDetails from "./UserDetails";
import SelectCard from "./SelectCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BackSection from "../../Components/Giftcards/BackSections";

const Voucher = (productReducer) => {
  const productId = productReducer?.productReducerDisocount?.id;
  const clientProductMapping = useSelector(
    (state) =>
      state?.ClientProductMappingbyIdReducer?.data?.[0]?.clientProductMapping
  );
  const [points, setPoints] = useState(0);
  const [cash, setCash] = useState(0);

  const productData = clientProductMapping?.filter(
    (product) => product?.productId === productId
  );

  const [updatedData, setUpdatedData] = useState([]);
  const [burnPoints, setBurnPoints] = useState(0);
  const [earnPoints, setEarnPoints] = useState(0);
  const [totalPayableAmount, setTotalPayableAmount] = useState(0);
  const productDetails = productReducer?.productReducer?.[0] || {};

  // Initial quantities
  const [quantities, setQuantities] = useState([]);
  // value.quantity
  useEffect(() => {
    let tempQuantity = [];
    if (productReducer?.arrayData?.arrayData) {
      productReducer?.arrayData?.arrayData?.map((value) => {
        return tempQuantity.push(value.quantity);
      });
      setQuantities(tempQuantity);
    } else {
      productReducer?.modalProductData?.map((value) => {
        return tempQuantity.push(value.quantity);
      });
      setQuantities(tempQuantity);
    }
  }, []);

  // Function to update quantities
  const updateQuantity = (index, change) => {
    const newQuantities = [...quantities];
    newQuantities[index] = Math.max(0, newQuantities[index] + change);
    setQuantities(newQuantities);
  };

  useEffect(() => {
    // Create updated data object based on quantities
    if (productReducer?.arrayData?.arrayData) {
      const updated = productReducer?.arrayData?.arrayData?.map(
        (value, index) => ({
          denomination: value.denomination,
          you_pay: value.you_pay,
          quantity: quantities[index],
          total: (value.you_pay * quantities[index]).toFixed(2),
        })
      );
      setUpdatedData(updated);
    } else {
      const updated = productReducer?.modalProductData?.map((value, index) => ({
        denomination: value.denomination,
        you_pay: value.you_pay,
        quantity: quantities[index],
        total: (value.you_pay * quantities[index]).toFixed(2),
      }));
      setUpdatedData(updated);
    }
  }, [quantities]);
  useEffect(() => {
    let totalDenomination = 0;
    let totalQuantity = 0;
    let totalPayableAmount = 0;

    updatedData?.forEach((item) => {
      totalDenomination += item.denomination * item.quantity;
      totalQuantity += item.quantity;
      // totalSavingAmount += item.saving * item.quantity;
      totalPayableAmount += item.you_pay * item.quantity;
    });
    setTotalPayableAmount(totalPayableAmount);
  }, [updatedData]);
  const currentBalance = useSelector(
    (state) => state.cardDataSliceReducer?.points
  );
  const handleTotalPayableAmountChange = (amount) => {
    setTotalPayableAmount(amount);
  };

  return (
    <>
      <BackSection />
      <div className="row">
        <div className="col-lg-8  ">
          <div className="row mx-0  py-3 cartGrid">
            <div className="col-lg-4 pe-0">
              <div className="d-flex w-100 h-100 justify-content-center">
                <Link>
                  <div className="w-100">
                    <img
                      className="w-144 card_main_image i-rd"
                      src={productDetails?.small}
                      alt="not-find"
                    />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-8 col-12 padding-50-1000-1400">
              <div className="row justify-content-between pb-2 mt-1">
                <div className="col-12 col-md-auto lh-1 cart-f pt-md-1">
                  {productDetails?.name}
                </div>
                <span className="pro-discount">
                  {productReducer?.productReducerDisocount?.customerDiscount !==
                    0 &&
                    `Discount Flat ${productReducer?.productReducerDisocount?.customerDiscount}%`}
                </span>
                <span className="Redeemable_online">
                  <span>
                    <i className="fa-solid fa-check check_icon"></i>
                  </span>
                  Redeemable Online
                </span>
              </div>
              <div
                id="pricing-table-card"
                className="pricing-table-card row mt-4"
              >
                {/* Heading Row */}
                <div className="heading-row col-lg-9">
                  <div className="heading">Value</div>
                  <div className="heading">Qty</div>
                  <div className="heading">You Pay</div>
                </div>

                {/* Data Rows */}
                {productReducer?.arrayData?.arrayData
                  ? productReducer?.arrayData?.arrayData?.map(
                      (value, index) => (
                        <div className="pricing-row col-lg-9 mb-2" key={index}>
                          <div className="value">₹{Math.round(value.denomination).toLocaleString()}</div>
                          <div className="quantity">
                            <button
                              className="quantity-btn-minus"
                              onClick={() => updateQuantity(index, -1)}
                            >
                              -
                            </button>
                            <span className="quantity-value">
                              {quantities[index]}
                            </span>
                            <button
                              className="quantity-btn-plus"
                              onClick={() => updateQuantity(index, +1)}
                            >
                              +
                            </button>
                          </div>
                          <div className="price">
                            ₹
                            {(value.you_pay * quantities[index]) % 1 === 0
                              ? Math.round(value.you_pay * quantities[index]).toLocaleString()
                              : Math.round(value.you_pay * quantities[index]).toLocaleString()}
                          </div>
                        </div>
                      )
                    )
                  : productReducer?.modalProductData?.map((value, index) => (
                      <div className="pricing-row col-lg-9 mb-2" key={index}>
                        <div className="value">₹{value.denomination}</div>
                        <div className="quantity">
                          <button
                            className="quantity-btn-minus"
                            onClick={() => updateQuantity(index, -1)}
                          >
                            -
                          </button>
                          <span className="quantity-value">
                            {quantities[index]}
                          </span>
                          <button
                            className="quantity-btn-plus"
                            onClick={() => updateQuantity(index, +1)}
                          >
                            +
                          </button>
                        </div>
                        <div className="price">
                          ₹
                          {(value.you_pay * quantities[index]) % 1 === 0
                            ? Math.round(value.you_pay * quantities[index])
                            : Math.round(value.you_pay * quantities[index])}
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          {/* Promo code will use in the future scope  */}
          {/* <PromoCode /> */}
          <RightCart
            productReducer={productReducer}
            updatedData={updatedData}
          />
          <SelectCard
            points={points}
            productReducer={productReducer}
            updatedData={updatedData}
            setEarnPoints={setEarnPoints}
          />
          {productData?.[0]?.maxEarnPoints > 0 && currentBalance > 0 && (
            <ThePoints
              cash={cash}
              setCash={setCash}
              points={points}
              setPoints={setPoints}
              productReducer={productReducer?.productReducerDisocount}
              updatedData={updatedData}
              productData={productData}
              onTotalPayableAmountChange={handleTotalPayableAmountChange}
              setBurnPoints={setBurnPoints}
            />
          )}

          {/* <Points productReducerDisocount={productReducerDisocount} arrayData={productReducer?.arrayData} /> */}
          <Refundable />
          <UserDetails
            points={points}
            cash={cash}
            totalPayableAmount={totalPayableAmount}
            quantities={quantities}
            productReducer={productReducer}
            updatedData={updatedData}
            burnPoints={burnPoints}
            earnPoints={earnPoints}
          />
        </div>
      </div>
    </>
  );
};
export default Voucher;
