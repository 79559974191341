import { call, put, takeLatest } from "redux-saga/effects";
import {
    onGetcms,
    onGetcmsSuccess,
    onGetcmsError,
} from "../../src/Store/Slices/cmsSlice";
import { cmsApi } from "../Context/cmsApi";


function* getcms() {
  try {
    const getcmsResponse = yield call(cmsApi);

    if (getcmsResponse.httpStatusCode === "200") {
      yield put(
        onGetcmsSuccess({
          data: getcmsResponse.response,
          message: getcmsResponse.errorMessage,
          status_code: getcmsResponse.httpStatusCode,
        })
      );
    } else {
      yield put(
        onGetcmsError({
          data: getcmsResponse.response,
          message: getcmsResponse.errorMessage,
          status_code: getcmsResponse.httpStatusCode,
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetcmsError({ data: {}, message, status_code: 400 }));
  }
}
export default function* cmsSaga() {
  yield takeLatest(onGetcms.type, getcms);
}
