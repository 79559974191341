import { call, put, takeLatest } from "redux-saga/effects";
import { callSwitchAccountPostApi } from "../Context/switchAccountApi";
import { onPostSwitchAccount, onPostSwitchAccountError, onPostSwitchAccountSuccess } from "../Store/Slices/switchAccountSlice";

function* PostSwitchAccount({ payload }) {
  try {
    const postSwitchAccountResponse = yield call(callSwitchAccountPostApi, payload);
    if (postSwitchAccountResponse.responseCode === "200") {
      yield put(
        onPostSwitchAccountSuccess({
          postData: postSwitchAccountResponse.response,
          message: postSwitchAccountResponse.responseMessage,
          status_code: postSwitchAccountResponse.responseCode,
        })
      );
    } else {
      yield put(
        onPostSwitchAccountError({
          data: postSwitchAccountResponse.response,
          message: postSwitchAccountResponse.responseMessage,
          status_code:postSwitchAccountResponse.responseCode
        })
      );
    }
  } catch (error) {
    const message = error.response.data.responseMessage  || "Something went wrong";
    yield put(onPostSwitchAccountError({ data: [], message, status_code: 400 }));
  }
}
export default function* switchAccountSaga() {
  yield takeLatest(onPostSwitchAccount.type, PostSwitchAccount);
}
