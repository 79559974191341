import API from "../Common/Endpoint/serviceConstrants";
import axiosIntegration from "../Common/Axios/axiosIntegration";
export const callSegmentApi = async (payload) => {
  const { data = {} } = await axiosIntegration.get(API.segmentApi, {
    params: {
      memberIdentifier: payload,
    },
  });
  return data;
};
