import API from "../Common/Endpoint/serviceConstrants";
import axiosInstance from "../Common/Axios/axiosInstance";

export const callCreateCategoryAllGetApi = async () => {
  const { data = [] } = await axiosInstance.get(API.getAll_categories);
  return data;
};
export const callCreateCategoryGetApi = async () => {
  const { data = [] } = await axiosInstance.get(API.getAll_categories);
  return data;
};
