import React from 'react'
import LetshopHandpick from '../../Components/LetshopHandpick/LetshopHandpick'
import Letshop from '../../Components/Letshop/Letshop'

const ProductCart = () => {
    return (
        <>
        <Letshop />
        <LetshopHandpick />
        </>
    )
}

export default ProductCart