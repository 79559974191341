import React from "react";
import ContactUsImg from "../../Assets/contactusimage.png";
import teleIcon from "../../Assets/teleIcon.png";
import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop";

const ContactUs = () => {
  return (
    <>
      <ScrollToTop />
      <section className="contact_us_page">
        <div className="py-0 py-md-4">
          {/* Banner Section */}
          <div className="row justify-content-center mb-4">
            <div className="col-md-8">
              <div className="d-flex justify-content-center">
                <h2 className="contact_banner_text">
                  How can we <br></br> help you today?
                </h2>
                <img
                  src={ContactUsImg}
                  alt="banner"
                  className="contact_us_banner"
                  style={{ maxWidth: "100%" }}
                />
              </div>
            </div>
          </div>

          {/* Form Section */}
          <div className="row container justify-content-center contact_us_form_box">
            <div className="col-md-5 ">
              <div className="card p-4 contact_modal">
                <form className="px-2 pt-3">
                  {/* Full Name */}
                  <div className="mb-3">
                    <label htmlFor="fullName" className="form-label">
                      Full Name *
                    </label>
                    <input
                      type="text"
                      className=""
                      id="fullName"
                      placeholder="Sandeep Mann"
                    />
                  </div>

                  {/* Email */}
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email Address *
                    </label>
                    <input
                      type="email"
                      className=""
                      id="email"
                      placeholder="test@gmail.com"
                    />
                  </div>

                  {/* Contact Number */}
                  <div className="mb-3">
                    <label htmlFor="contactNo" className="form-label">
                      Contact No *
                    </label>
                    <input
                      type="text"
                      className=""
                      id="contactNo"
                      placeholder="9999999999"
                    />
                  </div>

                  {/* Contact Me Checkbox */}
                  <div className="mb-3 form-check">
                    <label className="form-check-label" htmlFor="contactMe">
                      Contact Me *
                    </label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="contactMe"
                    />
                  </div>

                  {/* Select the Product */}
                  <div className="mb-3">
                    <label htmlFor="selectProduct" className="form-label">
                      Select the product
                    </label>
                    <select className="form-select" id="selectProduct">
                      <option>Amazon Gift Card</option>
                      <option>Apple Product</option>
                      <option>Google Play Store</option>
                    </select>
                  </div>

                  {/* How can we help you */}
                  <div className="mb-3">
                    <label htmlFor="helpText" className="form-label">
                      How can we help you
                    </label>
                    <textarea
                      className="form-control"
                      id="helpText"
                      rows=""
                      placeholder="Need help... if there is any cancellation charge if I cancel my booking."
                    ></textarea>
                  </div>

                  {/* File Upload */}
                  <div className="mb-3">
                    <label
                      htmlFor="fileInput"
                      className="form-label attach_weight"
                    >
                      Attach a File
                    </label>
                    <input
                      className="form-control chooseFile"
                      type="file"
                      id="fileInput"
                    />
                  </div>

                  {/* Submit Button */}
                  <div className="mb-3 mt-4">
                    <label></label>
                    <button type="submit" className="Submit_btn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Contact Information */}
          <div className="row justify-content-center reachUsPosition">
            <div className="col-md-6 text-center">
              <p className="d-flex justify-content-center align-item-center">
                <img className="img_reachUS" src={teleIcon} alt="img-phone" />
                <p className="reach_text">
                  <span className="firstSpan">Reach us at:</span>{" "}
                  <span className="secondSpan">+91 9152905290</span>
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
