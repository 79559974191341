/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import img from "../../Assets/is.png";
import BlackStar from "../../Assets/Star 63.png";
import { getMinPrice } from "../../Common/HOC/Hoc";

const ThePoints = ({
  productReducer,
  points,
  setPoints,
  updatedData,
  productData,
  onTotalPayableAmountChange,
  setBurnPoints,
  cash,
  setCash,
}) => {
  const currentBalance = useSelector(
    (state) => state.cardDataSliceReducer?.points
  );
  console.log(cash, "sdad");
  const maxBurnPoints = () => {
    const MaxEarnPoint = productData?.[0]?.maxEarnPoints;
    const data =
      currentBalance >
      (MaxEarnPoint / getMinPrice(productReducer?.priceDenominations)) *
        totalPayableAmount
        ? (MaxEarnPoint / getMinPrice(productReducer?.priceDenominations)) *
          totalPayableAmount
        : currentBalance;
    return data;
  };

  const handleSliderChange = (event) => {
    const value = Number(event.target.value); // Get the slider value as a number
    setPoints(value);

    // Calculate the cash amount based on selected points
    const pointsValue = (value / maxBurnPoints()) * totalPayableAmount; // Calculate the product price based on points selected
    setCash((totalPayableAmount - pointsValue).toFixed(2)); // Update cash
    onTotalPayableAmountChange((totalPayableAmount - pointsValue).toFixed(2));
    setBurnPoints(value);
  };

  let totalDenomination = 0;
  let totalQuantity = 0;
  let totalSavingAmount = 0;
  let totalPayableAmount = 0;
  if (!productReducer?.modalProductData) {
    updatedData?.forEach((item) => {
      totalDenomination += item.denomination * item.quantity;
      totalQuantity += item.quantity;
      totalSavingAmount += item.saving * item.quantity;
      totalPayableAmount += item.you_pay * item.quantity;
    });
  } else {
    productReducer?.modalProductData?.forEach((item) => {
      totalDenomination += item.denomination * item.quantity;
      totalQuantity += item.quantity;
      totalPayableAmount += item.you_pay * item.quantity;
    });
  }
  useEffect(() => {
    setCash(totalPayableAmount);
  }, [updatedData]);
  const currentPoints = useSelector(
    (state) => state.cardDataSliceReducer?.points
  );
  return (
    <div className="thepoints   mt-3 bnb ">
      <div className=" jlkkedit ddd p-4 pt-2 pb-2 mt-2">
        <div className="lsss">
          Pay with points or cash &nbsp;&nbsp;{" "}
          <img className="w-144 img" src={img} alt="img" />
        </div>
        <div className="t_aval_points mt-3">
          Total available Points{" "}
          <span>
            <img className="pb-1" width="10px" src={BlackStar} alt="star" />{" "}
          </span>
          <span>{currentPoints?.toLocaleString()}</span>
        </div>
      </div>
      <div className="d-flex justify-content-between p-3 pt-1">
        <h6 className="thelcpts mx-2 d-flex align-item-center">
          <i class="fa-solid fa-star fa-star_icon dstp-star"></i>
          <span className="pointswithprice"> &nbsp;{points?.toLocaleString()}</span>&nbsp;+&nbsp;
          <span>₹ {Math.round(cash)?.toLocaleString()}</span>
        </h6>
      </div>
      <div className="container jlkkedit ddd">
        <div className="formWrap row">
          <div
            className="d-flex d-flexCol col-10"
            style={{ marginLeft: "-25px", width: "82%" }}
          >
            <fieldset className="form-group">
              <div className="rangeSlider d-flex">
                <input
                  type="range"
                  min={0}
                  max={maxBurnPoints()}
                  value={points}
                  className="rsBar"
                  onChange={handleSliderChange}
                />
              </div>
            </fieldset>
          </div>
          <span className="last-cart-pp col-2">
            <img className="pb-1" width="14px" src={BlackStar} alt="star" />{" "}
            {points}{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ThePoints;
