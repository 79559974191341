import React, { useState } from 'react'
import AmazonGiftCard from '../Cards/AmazonGiftCard';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';

const Letshop = () => {
    const [activeTab, setActiveTab] = useState('nav-home');
    const ProductData = useSelector((state) => state?.supplierBrandListReducer?.data)

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleclose = () => {
        setIsDropdownOpen(!isDropdownOpen);
    }


    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <>
        <ScrollToTop />
        {ProductData.filter(item => item.enabled === true).map((item, index) => (
            <section key={index} className="letshop bg-white pb-4 mt-4">
                <div className="container big-rr mb-2 ">
                    <div className="row justify-content-center p-2 mt-4">
                        <div className="col-lg-3 plusminus mb-4">
                            <div className="img-cart text-center">
                                <img className="w-100" src={item.mobile} alt="Coupon" />
                            </div>
                            <div className="text-center"></div>
                        </div>
                        <div className="col-lg-9 mb-4">
                            <div id="carouselBrandControls" className="carousel slide mt-4" data-bs-ride="false" data-bs-touch="false">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="d-flex justify-content-between mb-3">
                                            <h4 className="mb-1 fnt-bold">{item.name}</h4>
                                            <span className="de-counter">{item.rewardPercentage}% <i className="fi fi-ss-star"></i> Rewards Earned</span>
                                        </div>
                                        <div>
                                            <div className="fs-14 pt-md-0 ">
                                                <p className="lsls">{item.description}</p>
                                            </div>
                                            <div className="d-grid gap-2 imp-btns d-flex justify-content-start mt-4">
                                                <Link onClick={toggleDropdown} data-bs-toggle="modal" data-bs-target="#instruction" className="mr-4"><span className="fnt-12px">Instructions</span></Link>
                                                <Link onClick={toggleDropdown} data-bs-toggle="modal" data-bs-target="#instruction" className="mr-4"><span className="fnt-12px">How to Redeem?</span></Link>
                                                <Link onClick={toggleDropdown} data-bs-toggle="modal" data-bs-target="#instruction" className="mr-4"><span className="fnt-12px">Terms &amp; Conditions</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <AmazonGiftCard />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        ))}

        <div className={`modal fade ${isDropdownOpen ? 'show' : ''}`}
            style={isDropdownOpen ? { display: 'block', background: 'rgba(0,0,0,0.5)' } : { display: 'none', background: 'none' }} id='instruction' >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <span onClick={handleclose} data-bs-dismiss="modal" aria-label="Close"><i className="fa-regular fa-circle-xmark fa-2x cross-icon"></i></span>
                    </div>
                    <div className="modal-body pt-0">
                        <div className="">
                            <div className="">
                                <nav className="text-center">
                                    <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                                        <Button
                                            onClick={() => handleTabClick('nav-home')}
                                            className={`nav-link ${activeTab === 'nav-home' ? 'active' : ''}`}
                                            id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home"
                                            type="button" role="tab" aria-controls="nav-home"
                                            aria-selected={activeTab === 'nav-home'}
                                            icon="fa-solid fa-book-open" text="&nbsp;Instructions"
                                        />
                                        <Button
                                            onClick={() => handleTabClick('nav-profile')}
                                            className={`nav-link ${activeTab === 'nav-profile' ? 'active' : ''}`}
                                            id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                                            type="button" role="tab" aria-controls="nav-profile"
                                            aria-selected={activeTab === 'nav-profile'}
                                            icon="fa-solid fa-book-open-reader"
                                            text="&nbsp;Terms &amp; Conditions"
                                        />
                                        <Button
                                            onClick={() => handleTabClick('nav-contact')}
                                            className={`nav-link ${activeTab === 'nav-contact' ? 'active' : ''}`}
                                            id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact"
                                            type="button" role="tab" aria-controls="nav-contact"
                                            aria-selected={activeTab === 'nav-contact'}
                                            icon="fa-solid fa-share-nodes"
                                            text="&nbsp;How to Redeem?"
                                        />
                                    </div>
                                </nav>
                                <div className="tab-content p-3 pt-1" id="nav-tabContent">
                                    <div className={`tab-pane fade ${activeTab === 'nav-home' ? 'active show' : ''}`} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <ul>
                                            <li>This Voucher can be redeemed at all company authorized TITAN WORLD Stores.</li>
                                            <li>Multiple vouchers (upto 5) can be used against single transaction.</li>
                                            <li>Gift Vouchers CANNOT be used Online.</li>
                                            <li>Voucher partial redemption is not allowed, single time usage only.</li>
                                            <li>Voucher is not applicable on discounted products and cannot be clubbed with any other offer in the store.</li>
                                            <li>Voucher cannot be redeemed for Titan Nebula collection of Watches.</li>
                                            <li>Multiple vouchers (upto 5) can be used against single transaction.
                                            </li>
                                            <li>Gift Vouchers CANNOT be used Online.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={`tab-pane fade ${activeTab === 'nav-profile' ? 'active show' : ''}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                        <ul>
                                            <li>The holder of the voucher is deemed to be the beneficiary.</li>
                                            <li>Beneficiary should announce the intent of using the voucher before making a purchase.</li>
                                            <li>Only valid vouchers at the sole discretion of TITAN shall be accepted for redemption.</li>
                                            {/* Rest of terms and conditions */}
                                        </ul>
                                    </div>
                                    <div className={`tab-pane fade ${activeTab === 'nav-contact' ? 'active show' : ''}`} id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <img className="w-100" src="img/step1.png" alt='#' />
                                                <h6>Go to Store or online platform</h6>
                                            </div>
                                            {/* Rest of steps */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Letshop