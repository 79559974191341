/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { onMemberPost } from "../../Store/Slices/memberSlice";
import {
  onGenerateTokenSubmit,
  onGenerateTokenSubmitReset,
} from "../../Store/Slices/generateTokenSlice";
import {
  onValidateLoginTokenReset,
  onValidateLoginTokenSubmit,
} from "../../Store/Slices/validateLoginTokenSlice";
import axiosIntegration from "../../Common/Axios/axiosIntegration";
import axiosIntegrationOtp from "../../Common/Axios/axiosIntegrationOtp";
import {
  onGetMember,
  onGetMemberReset,
} from "../../Store/Slices/getMemberSlice";
import API from "../../Common/Endpoint/serviceConstrants";
import { onSavePoints } from "../../Store/Slices/cardDataSlice";
import Loader from "../../Components/Loader/Loader";
const TempPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [accountData, setAccountData] = useState([]);
  const [isApiCallsDone, setIsApiCallsDone] = useState(false);
  const loginAuthData = useSelector((state) => state.loginAuthReducer);
  const generateToken = useSelector((state) => state.generateTokenSliceReducer);
  const loginToken = useSelector(
    (state) => state.validateLoginTokenSliceReducer
  );
  const V3Data = useSelector((state) => state.cardDataSliceReducer);

  const memberData = useSelector((state) => state.getMemberSliceReducer);
  const cards =
    memberData?.MemberData?.otherListAttribute?.[0]?.valueDetails?.filter(
      (cardData) => cardData.status !== "0"
    );

  const loginTokenData = loginToken.data;
  const urlIndex = location.pathname.split("/");

  const callSwitchAccountApi = async (payload) => {
    try {
      const { data = [] } = await axiosIntegration.post(
        API.switchAccountApi,
        payload
      );
      setAccountData((prevData) => [...prevData, data]);
    } catch (error) {
      console.log("Error calling switch account API:", error);
      throw error;
    }
  };

  useEffect(() => {
    sessionStorage.setItem("loginSessionToken", urlIndex[2]);
    sessionStorage.setItem("temporaryToken", urlIndex[3]);
    !sessionStorage.getItem("login") &&
      dispatch(
        onGenerateTokenSubmit({
          accessKey: V3Data?.accessKey,
          secrateKey: V3Data?.secrateKey,
          partnerCode: V3Data?.partnerCode,
          ClientCode: V3Data?.ClientCode,
        })
      );
    sessionStorage.setItem("login", true);
  }, []);

  useEffect(() => {
    if (generateToken?.status_code === 200) {
      axiosIntegration.defaults.headers[
        "access-token"
      ] = `${generateToken?.data?.accessToken}`;
      axiosIntegration.defaults.headers[
        "ClientCode"
      ] = `${V3Data.ClientCode}`;
      axiosIntegration.defaults.headers[
        "partnerCode"
      ] = `${V3Data.partnerCode}`;
      
      
      axiosIntegrationOtp.defaults.headers[
        "access-token"
      ] = `${generateToken?.data?.accessToken}`;
      dispatch(
        onValidateLoginTokenSubmit({
          loginSessionToken: sessionStorage.getItem("loginSessionToken"),
          integrationSecretKey: V3Data?.integrationSecretKey,
          temporaryToken: sessionStorage.getItem("temporaryToken"),
          urlReferrer: "",
          ClientCode: V3Data?.ClientCode,
          partnerCode: V3Data?.partnerCode,
        })
      );
      dispatch(onGenerateTokenSubmitReset());
    }
  }, [generateToken]);

  useEffect(() => {
    if (loginToken.status_code === "200") {
      dispatch(
        onGetMember({
          memberIdentifier: loginToken?.data?.memberIdentifier,
          identifierType: loginToken?.data?.identifierType,
          ClientCode: V3Data?.ClientCode,
          partnerCode: V3Data?.partnerCode,
        })
      );
      dispatch(
        onMemberPost({
          memberId: loginTokenData.memberIdentifier,
          pointBalance: 1,
          statusPointBalance: 2,
          firstName: loginTokenData.firstName,
          lastName: loginTokenData.lastName,
          emailId: loginTokenData.emailId,
          mobileNumber: loginTokenData.mobileNumber,
          clientId: loginAuthData?.data?.[0]?.clientId,
          dob: loginTokenData.dob,
          gender: loginTokenData.gender,
        })
      );
      dispatch(onValidateLoginTokenReset());
    }
  }, [loginToken]);

  useEffect(() => {
    if (memberData?.get_status_code === "200" && memberData?.MemberData) {
      // setIsLoading(true);
      dispatch(
        onSavePoints({
          points: Math.round(memberData?.MemberData?.memberBalance?.[0]?.pointBalance),
          identifierType: creditCards?.[0]?.identifierType,
          subIdentifier: creditCards?.[0]?.subIdentifier,
        })
      );

      // Check if there are cards; if not, set isApiCallsDone to true immediately
      if (!cards || cards.length === 0) {
        setIsApiCallsDone(true);
        // setIsLoading(false);
        dispatch(onGetMemberReset());
        return; // Exit early
      }

      const cardPromises = cards.map((cardData) =>
        callSwitchAccountApi({
          identifier: loginToken?.data?.memberIdentifier,
          subIdentifier:
            loginToken?.data?.subIdentifier || cardData?.identifier,
          identifierType:
            parseInt(loginToken?.data?.subIdentifierType) ||
            parseInt(cardData?.identifiertype),
          userSessionToken: sessionStorage.getItem("temporaryToken"),
          isAccountSwitch: false,
        })
      );

      Promise.all(cardPromises).then(() => {
        setIsApiCallsDone(true);
        dispatch(onGetMemberReset());
      });
    }
  }, [memberData]);
  const mergedArray = useMemo(
    () =>
      accountData
        .map((cardData) => cardData.response)
        ?.map((item1) => {
          const matchingItem = cards?.find(
            (item2) => item2.identifier === item1.subIdentifier
          );
          return {
            ...item1,
            ...(matchingItem ? { cardDetails: matchingItem } : {}),
          };
        }),
    [cards]
  );

  const creditCards = useMemo(
    () =>
      mergedArray?.filter(
        (card) => card?.cardDetails?.identifiertype === "1002"
      ),
    [mergedArray]
  );
  useEffect(() => {
    if (isApiCallsDone) {
      if (localStorage.getItem("ProductListPage")) {
        navigate(localStorage.getItem("ProductListPage"));
        localStorage.removeItem("ProductListPage");
      } else {
        navigate("/");
      }
      sessionStorage.setItem(
        "accountData",
        JSON.stringify(accountData.map((cardData) => cardData.response))
      );
      {
        cards && sessionStorage.setItem("cardInfo", JSON.stringify(cards));
      }
      dispatch(
        onSavePoints({
          points: Math.round(
            creditCards?.[0]?.memberBalance?.pointBalance ||
              memberData?.MemberData?.memberBalance?.[0]?.pointBalance
          ),
          identifierType: creditCards?.[0]?.identifierType,
          subIdentifier: creditCards?.[0]?.subIdentifier,
        })
      );
    }
  }, [isApiCallsDone, navigate]);

  return (
    <>
      <Loader />
    </>
  );
};

export default TempPage;
