import React from "react";
import { useSelector } from "react-redux";
import Dashboard from "./Dashboard";
const MyProfileContent = () => {
  const activeTab = useSelector((state) => state.activeTabSliceReducer.activeTab);
  return (
    <>
      <section className="py-3 header1">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center mt-4">
            <div className="col-md-8">
              <div className="tab-content" id="v-pills-tabContent">
                <Dashboard activeTab={activeTab} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyProfileContent;
