import React, { useEffect } from "react";

const RightCart = ({ productReducer, updatedData }) => {
  let totalDenomination = 0;
  let totalQuantity = 0;
  let totalPayableAmount = 0;

  updatedData?.forEach((item) => {
    totalDenomination += item.denomination * item.quantity;
    totalQuantity += item.quantity;
    // totalSavingAmount += item.saving * item.quantity;
    totalPayableAmount += item.you_pay * item.quantity;
  });

  return (
    <div>
      <div className="mt-3">
        <div className="bg-white rightCart bnb">
          <div className="info p-md-4 p-2 pt-2 pb-2">
            <div className="d-flex justify-content-between jlkk pb-2">
              <div className="mr-4 lsss2">{totalQuantity?.toLocaleString()} Gift Card(s)</div>
              <div className="lsss3">
                ₹
                {totalDenomination % 1 === 0
                  ? totalDenomination.toLocaleString()
                  : totalDenomination.toLocaleString()}
              </div>
            </div>

            <div className="d-flex justify-content-between jlkk pb-2">
              <div className="mr-4 lsss2">Discount</div>
              <div className="lsss3">
                ₹
                {(totalDenomination - totalPayableAmount) % 1 === 0
                  ? Math.round(totalDenomination - totalPayableAmount).toLocaleString()
                  : Math.round(totalDenomination - totalPayableAmount).toLocaleString()}
              </div>
            </div>

            <div className="d-flex justify-content-between jlkk">
              <div className="mr-4 lsss3 lssss4">Total Amount</div>
              <div className="lsss3">
                ₹
                {totalPayableAmount % 1 === 0
                  ? Math.round(totalPayableAmount).toLocaleString()
                  : Math.round(totalPayableAmount).toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightCart;
