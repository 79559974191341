import React from "react";
import { Modal, Nav, Tab } from "react-bootstrap";
import step1 from "../../Assets/step1.png";
import step2 from "../../Assets/step2.png";
import step3 from "../../Assets/step3.png";
import modelImg from "../../Assets/remove.png";

const ModalComponent = ({ show, handleClose, productDetails, activeTab }) => {
  const renderHtmlContent = () => {
    return { __html: productDetails?.productReducer?.tncContent };
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="exampleModalLabel"
      centered
    >
      <div className="modal-header justify-content-center p-1">
        <button
          type="button"
          className="modal-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          <img className="w-35px" src={modelImg} alt="Close" />
        </button>
      </div>
      <Modal.Body className="pt-0 mt-4 p-4">
        <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
          <Nav variant="tabs" className="justify-content-around mb-3">
            <Nav.Item>
              <Nav.Link eventKey="terms">
                <i className="fa-solid fa-book-open-reader"></i> &nbsp; Instructions
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="redeem">
                <i className=""></i> &nbsp;Terms &
                Conditions
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="terms">
              <div dangerouslySetInnerHTML={renderHtmlContent()} />
            </Tab.Pane>
            <Tab.Pane eventKey="redeem">
              {/* <div className="row">
                <div className="col-lg-4">
                  <img className="w-100" src={step1} alt="Step 1" />
                  <h6>Go to Store or online platform</h6>
                </div>
                <div className="col-lg-4">
                  <img className="w-100" src={step2} alt="Step 2" />
                  <h6>Select your choice of product</h6>
                </div>
                <div className="col-lg-4">
                  <img className="w-100" src={step3} alt="Step 3" />
                  <h6>Share your coupon code or apply your code</h6>
                </div>
              </div> */}
                            <div dangerouslySetInnerHTML={renderHtmlContent()} />

            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

export default ModalComponent;
