import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    data: {},
    error: {},
    message: "",
  },
  reducers: {
    onthemePost: (state,{payload}) => {
      return {
        ...state,
        data: payload,
        message: "",
      };
    },

  }
});
export const { onthemePost } =
themeSlice.actions;

export default themeSlice.reducer;
