/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onGetcms } from "../../Store/Slices/cmsSlice";
import { useParams } from "react-router-dom";
import FooterTopimage from "../../Assets/carttop.png";
import Button from "../../Components/Button/Button";
const CmsPage = () => {
  const PageName = useParams();
  const dispatch = useDispatch();
  const getcmsData = useSelector((state) => state?.cmsReducer.getcms);
  useEffect(() => {
    dispatch(onGetcms());
  }, []);
  const FilteredData =
    Array.isArray(getcmsData) &&
    getcmsData.filter((item) => item.title === PageName.pagetype);
  const FooterData = [];
  for (let i = 0; i < FilteredData.length; i++) {
    FooterData.push(FilteredData[0]);
  }

  return (
    <section className="letshop cms">
      <div className="container big-rr mb-2">
        <div className="row">
          <div className="">
            <div className="text-center">
              <h4 className="footer_head">{FooterData[0]?.title}</h4>
            </div>
            <img className="w-100 hip" src={FooterTopimage} alt="Footer Top" />
          </div>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <Button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-controls="collapseOne"
                  strongText={FooterData[0]?.title}
                />
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <strong>
                    <p>{FooterData[0]?.shortDescription}</p>
                  </strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: FooterData[0]?.longDescription,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CmsPage;
/* eslint-enable react-hooks/exhaustive-deps */