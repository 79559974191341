
import { react } from "@babel/types";
import React from "react";
import { useNavigate } from "react-router-dom";
const BackSections = () => {
  const navigate = useNavigate();

  const handleClick=()=>{
    navigate(-1);
  }
  return(
    <section className="py-xl-3 pt-1 pb-2 desktop-hide  mar-4">
      <div className="container-fluid">
        <div className="m-xl-n3 m-md-n2 m-n1">
          <div className="p-md-2 p-1 back" onClick={handleClick}>
            {/* <i className="fa-solid fa-arrow-left clr-black-blk"></i> */}
            <i class="fa-solid fa-chevron-left clr-black-blk"></i> &nbsp; Back
          </div>
        </div>
      </div>
    </section>
  )
}
  export default BackSections;