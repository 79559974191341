import React, { useEffect, useState } from "react";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import amazon from "../../Assets/image.png";
import BlueStar from "../../Assets/BlueStar.png";
import BackArr from "../../Assets/BackArr.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onGetOrder } from "../../Store/Slices/orderSlice";
import BackSections from "../Giftcards/BackSections";

const RetrieveCode = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderData, setOrderData] = useState([]);
  const [isOptEnable, setIsOptEnable] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const client_Id = useSelector((state) => state.loginAuthReducer.data);
  const clientConfigurationData = useSelector(
    (state) => state.clientConfigurationReducer.clientConfigurationData
  );
  const validateLoginTokenData = useSelector(
    (state) => state.validateLoginTokenSliceReducer.data
  );
  const clientProduct = useSelector(
    (state) => state.productReducer?.productById?.[0]?.products
  );

  const userOrderData = useSelector((state) => state.orderReducer);

  const matchedClientData = clientConfigurationData?.find(
    (config) =>
      Array.isArray(client_Id) &&
      client_Id?.some((client) => client.clientId === config.clientId)
  );

  useEffect(() => {
    setIsOptEnable(matchedClientData?.otpRedeem);
  }, [clientConfigurationData, client_Id]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(onGetOrder(validateLoginTokenData.memberIdentifier));
  }, []);

  useEffect(() => {
    setOrderData(userOrderData.orderData);
  }, [userOrderData]);

  const handleRetrieveCode = (id, deno) => {
    isOptEnable
      ? navigate("/otp", { state: { orderId: id, denomination: deno } })
      : navigate(`/order-detail?orderID=${id}&denomination=${deno}`);
  };

  function getThumbnailUrlById(id) {
    const item = clientProduct?.find((entry) => entry.id === id);
    return item ? item.base : null;
  }
  return (
    <>
      <ScrollToTop />
      <div className="bg">
     <BackSections/>
        <section className="m-2">
          <div className="container p-2">
            <div className="row justify-content-center">
              {!isMobile && (
                <div className="gift-head">
                  <h2>Orders</h2>
                </div>
              )}
              <div
                className={`orders-container ${
                  isMobile ? "mobile-cards" : "desktop-cards"
                }`}
              >
                {Array.isArray(orderData) &&
                  orderData?.map((product, index) => (
                    <div className="order-card mr-4 mt-2" key={index}>
                      <div className="card-content">
                        <span className="order-image">
                          <img
                            src={getThumbnailUrlById(product.clientBrandId)}
                            alt="Amazon Logo"
                          />
                        </span>
                        <div className="order-details">
                          <h3 className="order-heading">{`${product.name} ₹ ${product.denomination} e-Gift-Card`}</h3>
                          <div className="mt-2">
                            <div className="order-info">
                              <span className="order-info1">
                                <span>{product.orderId}</span>
                                <p>Order Number</p>
                              </span>
                              <span className="order-info1">
                                <span>{new Date().toDateString()}</span>
                                <p>Order Date</p>
                              </span>
                            </div>
                            <div className="order-info">
                              <span className="order-info1">
                                <span>
                                  {" "}
                                  <img src={BlueStar} alt="bluestar" />{" "}
                                  {product.burnPoints}+ ₹{product.orderAmount}
                                </span>
                                <p>Order Amount</p>
                              </span>
                              <span className="order-info1">
                                <span>{product.cardType}</span>
                                <p>Quantity</p>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        className="retrieve-btn mt-3"
                        onClick={() =>
                          handleRetrieveCode(
                            product.orderId,
                            product.denomination
                          )
                        }
                      >
                        Retrieve Code
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default RetrieveCode;
