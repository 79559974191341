/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState, useMemo } from "react";

import staticGiftLogo from "../../Assets/NewList/powered-gift.png";
import logo from "../../Assets/NewList/Bom.png";
import CrossBTN from "../../Assets/cross-earn.png";
import star from "../../Assets/star.png";
import { GetTranslationData } from "../../Components/GetTranslationData/GetTranslationData ";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { onGetPromotional } from "../../Store/Slices/promotionalSlice";
import { useDispatch, useSelector } from "react-redux";
import order from "../../Assets/order.png";
import profile from "../../Assets/profileNew.png";
import contact from "../../Assets/contact.png";
import { onGetofferMaster } from "../../Store/Slices/offerMasterSlice";

const DesktopHeader = ({
  scroll,
  matchCategory,
  validateLoginTokenData,
  getInitials,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);
  const login_label = GetTranslationData("UIAdmin", "login_label");
  const my_Profile = GetTranslationData("UIAdmin", "my_Profile");
  const isLogin = sessionStorage.getItem("login");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const promotionalList = useSelector((state) => state.promotionalReducer);
  const [promotinalData, setPromotionalData] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const visibleItems = 4;
  const firstName = validateLoginTokenData.firstName;
  const lastName = validateLoginTokenData.lastName;
  const [placeholder, setPlaceholder] = useState("Search for gift cards");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const loginAuthData = useSelector((state) => state.loginAuthReducer);

  useEffect(() => {
    if (loginAuthData?.data?.length) {
      dispatch(onGetPromotional());
      dispatch(onGetofferMaster());
    }
  }, []);
  const handleClick = (item) => {
    navigate(`/giftcard?productId=${item?.id}`, { state: { item } });
    setSearchTerm("");
    setFilteredResults([]);
    setIsSearching(false); // Set searching to false as item is selected
    // Clear the dropdown
    setIsSearchActive(false);
  };

  const productData = useSelector(
    (state) => state.productReducer.productById?.[0]?.products
  );

  useEffect(() => {
    const filteredData =
      Array.isArray(promotionalList?.GetPromotional) &&
      promotionalList?.GetPromotional?.filter((item) => item.enabled === true);
    setPromotionalData(filteredData);
  }, [promotionalList?.GetPromotional]);
  const [showPromotional, setShowPromotional] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const handleChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    setIsSearching(true);
    setIsSearchActive(true);

    if (searchValue.trim() === "") {
      setFilteredResults([]);
      setIsSearchActive(false);
      return;
    }

    const filteredData = productData?.filter((item) =>
      item?.name?.toLowerCase().includes(searchValue?.toLowerCase())
    );
    setFilteredResults(filteredData);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setFilteredResults([]);
      setSearchTerm("");
      setIsSearchActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleImageClick = (item) => {
    navigate(`/productlist?categoryId=${item}`, { state: { item } });
  };

  useEffect(() => {
    if (searchTerm) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [searchTerm]);

  const currentBalance = useSelector(
    (state) => state.cardDataSliceReducer?.points
  );

  const V3Data = useSelector((state) => state.cardDataSliceReducer);

  useEffect(() => {
    if (location.pathname.endsWith("/otp")) {
      setShowPromotional(false);
    }
  }, [location.pathname]);

  const handleLogin = () => {
    const pathName = window.location.pathname;
    const search = window.location.search;
    localStorage.setItem("ProductListPage", pathName + search);
  };

  const cardData = JSON.parse(sessionStorage.getItem("accountData"));
  const cardInfo = JSON.parse(sessionStorage.getItem("cardInfo"));

  const mergedArray = useMemo(
    () =>
      cardData?.map((item1) => {
        const matchingItem = cardInfo?.find(
          (item2) => item2.identifier === item1.subIdentifier
        );
        return {
          ...item1,
          ...(matchingItem ? { cardDetails: matchingItem } : {}),
        };
      }),
    [cardData, cardInfo]
  );

  const creditCards = useMemo(
    () =>
      mergedArray?.filter(
        (card) => card?.cardDetails?.identifiertype === "1002"
      ),
    [mergedArray]
  );
  const memberData = useSelector(
    (state) =>
      state.getMemberSliceReducer?.MemberData?.memberBalance?.[0]?.pointBalance
  );

  return (
    <>
      {isSearchActive && <div className="screen-blur" />}
      <header className="header forTab-hide mobile-hide">
        <div className={`fixheader top-hh ${scroll && "sticky"}`}>
          {showPromotional &&
            Array?.isArray(promotinalData) &&
            promotinalData?.map((item) => (
              <div className="top-hh">
                <div className="top-menu oaoa1">
                  <div className="d-flex ml-10 ">
                    <p className="promo">&nbsp;{item?.titleText}</p>
                    <p className="mar-earn">
                      <Link to={item?.link} className="exp hoverPromo">
                        {item?.linkText} <i class="fa-solid fa-arrow-right"></i>
                      </Link>
                    </p>
                  </div>
                  <button
                    className="close-Btn "
                    onClick={() => setShowPromotional(false)}
                  >
                    <img src={CrossBTN} alt="img" className="px-2" />
                  </button>
                </div>
              </div>
            ))}
          <div className="main-header fitted ">
            <div className="container">
              <div className="row align-items-lg-center">
                <div className="col-xl-3 col-lg-4 col-7 order-0 order-lg-0"></div>
                <div className="col-xl-3 col-lg-2 col-5 order-1 order-lg-2"></div>
                <div className="col-xl-6 col-lg-6 col-12 order-2 order-lg-1"></div>
              </div>
            </div>
          </div>
          <div className="botheader">
            <div className="container">
              <div className="d-flex  top-menu">
                <div className=" d-xl-none d-none p-3">
                  <div className="mobilemenulogo text-center">
                    <Link to="/">{/* <img src={Logo} alt="img" /> */}</Link>
                  </div>
                  <div className="menuclosebtn">
                    <Link to="#">
                      <i className="las la-times"></i>
                    </Link>
                  </div>
                </div>
                <div className="thelogo">
                  <div className="button"></div>
                  <div className="logo">
                    <Link
                      to="/"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "7px",
                      }}
                    >
                      <img
                        src={logo}
                        alt="img"
                        style={{ width: "104.48px", height: "31.87px" }}
                      />
                      <img
                        src={staticGiftLogo}
                        alt="img"
                        style={{ width: "54.18px", height: "31.09px" }}
                      />
                      {/* <img src={Logo2} alt="img" /> */}
                    </Link>
                  </div>
                </div>
                <div className="thecate ml-10">
                  <nav id="cssmenu" className="">
                    <ul>
                      {Object.keys(matchCategory)

                        .slice(0, visibleItems)
                        .map((item1, index) => (
                          <li key={index}>
                            <Link
                              to={`/productlist?categoryId=${item1}`}
                              state={{ item1 }}
                            >
                              {item1}
                            </Link>
                          </li>
                        ))}
                      {visibleItems < Object.keys(matchCategory)?.length && (
                        <li className="more-button">
                          <Link>
                            More <i class="fas fa-angle-down"></i>
                          </Link>
                          <ul className="submenulist">
                            {Object.keys(matchCategory)
                              .slice(visibleItems)
                              .map((item1, index) => (
                                <li
                                  key={index + visibleItems}
                                  className={`menu-item ${
                                    selectedProduct === item1 ? "hovered" : ""
                                  }`}
                                  onMouseEnter={() => setSelectedProduct(item1)}
                                  onMouseLeave={() => setSelectedProduct(null)}
                                >
                                  <div
                                    className="a"
                                    onClick={() => handleImageClick(item1)}
                                  >
                                    {item1}
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
                <div className="thesearch mr-4" ref={dropdownRef}>
                  <div className="searchboxmaindiv">
                    <div className="searchbox">
                      <a id="btnsearch">
                        <i className="las la-search"></i>
                      </a>
                      <input
                        name="ctl00$txtsearch"
                        type="text"
                        id="txtsearch"
                        className="autosuggest"
                        autoComplete="off"
                        placeholder={placeholder}
                        value={searchTerm}
                        onChange={handleChange}
                      />
                    </div>
                    {searchTerm && (
                      <ul className="dropdown">
                        {filteredResults.length > 0
                          ? filteredResults.map((item) => (
                              <li
                                onClick={() => handleClick(item)}
                                key={item.id}
                                className="dropdown-item d-flex align-item-center"
                              >
                                <i
                                  className="las la-search mx-2"
                                  style={{ fontSize: "1.5em" }}
                                ></i>{" "}
                                {item.name}
                              </li>
                            ))
                          : isSearching && (
                              <li className="dropdown-item">
                                No records found
                              </li>
                            )}
                      </ul>
                    )}
                  </div>
                </div>

                <div className="theprofile">
                  <div className={`top-right clearfix`}>
                    {isLogin ? (
                      <ul className="">
                        {creditCards?.length || memberData ? (
                          <li id="ctl00_pointdesk" className="prodiscounter44">
                            <i className="fa-solid fa-star fa-star_icon buy mar"></i>{" "}
                            {currentBalance?.toLocaleString()}
                          </li>
                        ) : (
                          ""
                        )}
                        <li className="meiconbtn">
                          <Link to="#" className="">
                            <div className="profile">
                              <div className="profile-initials">
                                {getInitials(firstName, lastName)}
                              </div>
                            </div>
                          </Link>
                          <div className="logindropbox logindrop-ch">
                            <div className="logindrop2">
                              <ul className="pb-1">
                                {/* <li>
                                <Link to=""  className="link-border">{my_Orders}</Link>
                              </li> */}
                                <li className="profile_li">
                                  <img
                                    className="icon-sz"
                                    src={profile}
                                    alt="profie"
                                  />
                                  <Link to="/myprofile" className="link-border">
                                    {my_Profile}
                                  </Link>
                                </li>
                                <li className="profile_li">
                                  <img
                                    className="icon-sz"
                                    src={order}
                                    alt="order"
                                  />
                                  <Link to="/orders" className="link-border">
                                    {"Orders"}
                                  </Link>
                                </li>
                                <li className="profile_li">
                                  <img
                                    className="icon-sz"
                                    src={contact}
                                    alt="contact"
                                  />
                                  <Link to="/contact-us">{"Contact us"}</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    ) : (
                      <ul className="">
                        <li id="ctl00_lnklogin">
                          <Link
                            to={V3Data?.SignInUrl}
                            onClick={() => handleLogin()}
                            className="login_btn"
                          >
                            {login_label}
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default DesktopHeader;
