import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../Components/Button/Button'

const OrderConfirm = () => {
    return (
        <>
            <section className="mt-100px mb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 pb20">
                            <div className="row justify-content-between">
                                <div className="order-tracking completed">
                                    <span className="is-complete"></span>
                                    <p>Add to Cart</p>
                                </div>
                                <div className="order-tracking completed">
                                    <span className="is-complete"></span>
                                    <p>Checkout</p>
                                </div>
                                <div className="order-tracking completed">
                                    <span className="is-complete"></span>
                                    <p>Order Placed</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="letshop">
                <div className="container big-rr2 mb-1">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-12 losp">
                            <div>
                                <h1 style={{ fontWeight: 700 }}>THANK YOU</h1>
                                <div className="fot-o">
                                    <div className="this-left">
                                        <h4 className="the-para-magic2">This gift card vouchers will be emailed to order hp.paygavhan@gmail.com within 5 minutes. <br />If not received please check your junk mail.</h4>
                                    </div>

                                    <div className="this-right">
                                        <h6>Order Number - <strong>Z15254</strong></h6>
                                        <h6>Total Amount - <strong>₹5122</strong></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="row bnn33 pt-33 pb-4">
                    <h4 className="h-summ">Order Summary</h4>
                    <div className="col-lg-8 border">
                        <div className="container p-0">
                            <div className="row">
                                <div className="col-lg-12 col-12 mt-4">
                                    <div className="pad3">
                                        <div className="d-flex justify-content-between mb-3">
                                            <h5 className="mb-1 fnt-bold"><span>₹ 12,000 &nbsp;</span>Amazon Gift Card</h5>
                                            <span className="de-counter">11.5% <i className="fi fi-ss-star"></i> Rewards Earned</span>
                                        </div>
                                        <div className="d-bb2 mr-2 mt-2">
                                            <div className="d-bb">
                                                <p className="mbs">₹12,541/-</p>
                                                <p><span className="offs">Discount Flat 5%</span></p>
                                            </div>
                                            <div>
                                                <div className="d-flex justify-content-between text-center">
                                                    <div className="font-blk mr-10">
                                                        <p>You pay</p>
                                                    </div>
                                                    <div className="font-grn">
                                                        <p className="fnt-15">₹4,850</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <div className="font-blk mr-10">
                                                        <p>Saving</p>
                                                    </div>
                                                    <div className="font-blk">
                                                        <p className="fnt-15">₹150</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-12 mt-4">
                                    <div className="pad3">
                                        <div className="d-flex justify-content-between mb-3">
                                            <h5 className="mb-1 fnt-bold"><span>₹ 12,000 &nbsp;</span>Amazon Gift Card</h5>
                                            <span className="de-counter">11.5% <i className="fi fi-ss-star"></i> Rewards Earned</span>
                                        </div>
                                        <div className="d-bb2 mr-2 mt-2">
                                            <div className="d-bb">
                                                <p className="mbs">₹12,541/-</p>
                                                <p><span className="offs">Discount Flat 5%</span></p>
                                            </div>
                                            <div>
                                                <div className="d-flex justify-content-between text-center">
                                                    <div className="font-blk mr-10">
                                                        <p>You pay</p>
                                                    </div>
                                                    <div className="font-grn">
                                                        <p className="fnt-15">₹4,850</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <div className="font-blk mr-10">
                                                        <p>Saving</p>
                                                    </div>
                                                    <div className="font-blk">
                                                        <p className="fnt-15">₹150</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="bg-white rightCart bnb">
                            <div className="info">
                                <div className="d-flex justify-content-between jlkk ">
                                    <div>Deliver to:</div>
                                    <div style={{ fontWeight: 700 }}>Jaswant Rawat</div>
                                </div>
                                <div className="d-flex justify-content-between jlkk">
                                    <div>Mobile No:</div>
                                    <div style={{ fontWeight: 700 }}>9650531790</div>
                                </div>
                                <div className="d-flex justify-content-between jlkk">
                                    <div>Email:</div>
                                    <div style={{ fontWeight: 700 }}>jaswant@way2webworld.com</div>
                                </div>
                            </div>
                            <div className="ghh mt-3">
                                <div className="d-flex justify-content-between mb-2 ">
                                    <div className="gt">
                                        <span className="fnt-15">Sub Total</span>
                                    </div>
                                    <div className="font-grn">
                                        <span className="fnt-15">₹ 17000</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-2">
                                    <div className="gt">
                                        <span className="offs">Discount Flat 5%</span>
                                    </div>
                                    <div className="font-grn">
                                        <span className="fnt-15">₹ 850</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-2">
                                    <div className="gt">
                                        <span className="fnt-15">Points Used</span>
                                    </div>
                                    <div className="font-grn">
                                        <span className="fnt-15">₹ 450</span>
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between mb-2">
                                    <div className="gt">
                                        <span className="fnt-15">Total</span>
                                    </div>
                                    <div className="font-grn">
                                        <span className="fnt-15">₹ 15060</span>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="p-md-2 d-grid ">
                                <Link to="/" className="cnt-shop-blue">
                                    <Button
                                        className="btn py-3 py-md-2 btss2"
                                        text="Go to My Order &nbsp;"
                                        icon="fa fa-arrow-right"
                                    />
                                </Link>
                            </div>
                            <div className="p-md-2 d-grid ">
                                <Link to="productcart.html" className="cnt-shop">
                                    <Button
                                        className="btn py-3 py-md-2 btss "
                                        text="Continue Shopping &nbsp;"
                                        icon="fa fa-arrow-right"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderConfirm