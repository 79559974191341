import { createSlice } from "@reduxjs/toolkit";

export const cms = createSlice({
  name: "cms",
  initialState: {
    getcms: {},
  },
  reducers: {
    onGetcms: (state) => {
      return {
        getcms: {},
      };
    },
    onGetcmsSuccess: (state, { payload }) => {
      const { data = {}, message = "", status_code = 200 } = payload;
      return {
        ...state,
        getcms: data,
        message,
        status_code,
      };
    },
    onGetcmsError: (state, { payload }) => {
      const { data = {}, message = "", status_code = 400 } = payload;
      return {
        ...state,
        getcms: data,
        message,
        status_code,
      };
    },
    OnGetcmsReset: (state) => {
      return {
        ...state,
        getcms: {},
        message: "",
        status_code: null,
      };
    },
  },
});

export const {
  onGetcms,
  onGetcmsSuccess,
  onGetcmsError,
  OnGetcmsReset,
} = cms.actions;

export default cms.reducer;
