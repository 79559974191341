import React, { useState, useEffect } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import crossIcon from './../../Assets/Shape.png'

const SortBy = ({ products, onSortChange, onClearSort }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedSort, setSelectedSort] = useState("");

  useEffect(() => {
    if (Array.isArray(products)) {
      let sorted = [...products];
      if (selectedSort === "alphabet") {
        sorted.sort((a, b) => a.name.localeCompare(b.name));
        onSortChange(sorted);
      }else{
        onSortChange(sorted);
      }
    }
  }, [selectedSort, products, onSortChange]);

  const handleSortChange = (e) => {
    const value = e.target.checked ? "alphabet" : "";
    const params = new URLSearchParams(location.search);
    value ?  params.set("sortBy", value): params.delete("sortBy");
    navigate(`${location.pathname}?${params.toString()}`);
    setSelectedSort(value);
  };

  const clearFilter = (e) => {
    e.preventDefault();
    setSelectedSort(""); 
    const params = new URLSearchParams(location.search);
    params.delete("sortBy");
    navigate(`${location.pathname}?${params.toString()}`);
    onClearSort();
  };

  return (
    <div className="filterdrpbtn">
      <h3 className="fill">Sort By</h3>
      <div className="sort-type">
        <label className="customcheckbox">
          <input
            id="alphabet"
            type="checkbox"
            name="Alphabet"
            checked={selectedSort === "alphabet"}
            onChange={(e)=>handleSortChange(e)}
          />
          <label className="sort-label">Alphabet</label>
          <span className="checkmark"></span>
        </label>
        <div className="clearAll_position">
        <i className="fa-solid fa-circle-xmark cursorPointer" onClick={(e)=>{clearFilter(e)}}></i>
          <Link className="text-right" onClick={(e)=>clearFilter(e)}>
            Clear Sort
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SortBy;
