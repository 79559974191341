import { call, put, takeLatest } from "redux-saga/effects";
import { onPointsSubmit, onPointsError, onPointsSuccess } from "../Store/Slices/pointsSlice";
import { pointApi } from "../Context/pointsApi";
function* Point({ payload }) {
  try {
    const postResponse = yield call(pointApi, payload);
    if (postResponse.httpStatusCode === "200") {
      yield put(
        onPointsSuccess({
          data: postResponse.response,
          message: postResponse.response,
        })
      );
    } else {
      yield put(
        onPointsError({
          data: postResponse.response,
          message: postResponse.errorMessage,
          status_code:postResponse.httpStatusCode
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onPointsError({ data: {}, message, status_code: error?.response?.data?.httpStatusCode }));
  }
}
export default function* PointSaga() {
  yield takeLatest(onPointsSubmit.type, Point);
}
