import React, { useEffect, useState } from "react";
import GiftCardDetailsSection from "./GiftCardDetailsSection ";
import GiftCardRedemptionSection from "./GiftRedeemSection";
import FeaturedGiftCardsSection from "./FeaturedGiftCardsSection ";
import BackSection from "./BackSections";
import ModalComponent from "./Modal";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onProductByProductIdSubmit } from "../../Store/Slices/productSlice";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

const GiftCard = () => { 
  const location = useLocation();
  const dispatch = useDispatch();
  const productData = location.state?.productData?.item;

  const [enhancedProductReducer, setEnhancedProductReducer] = useState(null);

  const productReducer = useSelector((state) => state.productReducer?.productByProductId);
  const productDiscount = useSelector(state => state?.ClientProductMappingbyIdReducer?.data?.[0]?.clientProductMapping);

  const url = window.location.href.split("=")[1];

  useEffect(() => {
    if (url) {
      dispatch(onProductByProductIdSubmit(url));
    }
  }, [url, productData?.id]);

  useEffect(() => {
    if (productReducer && productDiscount) {
      const discount = productDiscount.find(discountItem => discountItem.productId === productReducer[0]?.id);
      const customerDiscount = discount?.customerDiscount;
      setEnhancedProductReducer({
        ...productReducer[0],
        customerDiscount
      });
    }
  }, [productReducer, productDiscount]);
  return (
    <>
    <ScrollToTop />
    <div className="bg">
      <BackSection />
      <GiftCardDetailsSection productReducer={enhancedProductReducer} />
      <GiftCardRedemptionSection productReducer={enhancedProductReducer} />
      <FeaturedGiftCardsSection />
      <ModalComponent productReducer={enhancedProductReducer} />
    </div>
    </>
  );
};

export default GiftCard;
