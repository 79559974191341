import { call, put, takeLatest } from "redux-saga/effects";
import {
    onGetbannerMaster,
    onGetbannerMasterSuccess,
    onGetbannerMasterError,
} from "../../src/Store/Slices/bannerMasterSlice";
import { bannerMasterApi } from "../Context/bannerMasterApi";


function* getbannerMaster() {
  try {
    const getbannerMasterResponse = yield call(bannerMasterApi);

    if (getbannerMasterResponse.httpStatusCode === "200") {
      yield put(
        onGetbannerMasterSuccess({
          data: getbannerMasterResponse.response,
          message: getbannerMasterResponse.errorMessage,
          status_code: getbannerMasterResponse.httpStatusCode,
        })
      );
    } else {
      yield put(
        onGetbannerMasterError({
          data: getbannerMasterResponse.response,
          message: getbannerMasterResponse.errorMessage,
          status_code: getbannerMasterResponse.httpStatusCode,
        })
      );
    }
  } catch (error) {
    const message = error.response || "Something went wrong";
    yield put(onGetbannerMasterError({ data: {}, message, status_code: 400 }));
  }
}
export default function* bannerMasterSaga() {
  yield takeLatest(onGetbannerMaster.type, getbannerMaster);
}
